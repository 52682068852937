<template>
  <modal :is-open="isOpen" :title="$t('CONFIRMATION')" @close="$emit('close')">
    <slot></slot>
    <template slot="foot">
      <button @click="$emit('close')" class="button" :disabled="loading">
        {{ $t("CANCEL") }}
      </button>
      <button
        @click="$emit('validate')"
        class="button is-primary"
        :class="{ 'is-loading': loading }"
      >
        {{ $t("VALIDATE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: { Modal },
  props: ["isOpen", "loading"]
};
</script>

<style scoped></style>
