<template>
  <div>
    <h2 class="flex-grow title is-size-4">{{ $t("TEXT_AND_LINK") }}</h2>
    <ValidationProvider
      class="block"
      :name="`&quot;${$t('NAME')}&quot;`"
      rules="required"
      v-slot="{ flags: { pristine }, errors }"
      immediate
    >
      <b-field
        :label="$t('TITLE')"
        :type="!pristine && errors[0] ? 'is-danger' : ''"
      >
        <b-input
          v-model="name"
          :placeholder="$t('VISUAL_NAME')"
          :disabled="disabled"
        ></b-input>
      </b-field>
      <div v-show="!pristine && errors[0]" class="has-text-danger -mt-2">
        {{ errors[0] }}
      </div>
    </ValidationProvider>

    <ErrorMessage :error="listImagesError" class="mb-5">
      <ValidationProvider
        class="block"
        :name="`&quot;${$t('IMAGE')}&quot;`"
        rules="required"
        v-slot="{ flags: { pristine }, errors }"
        immediate
      >
        <b-field v-if="!imagePreview">
          <template #label>
            <label class="label inline-block" style="margin: 0">
              {{ $t("IMAGE") }}
            </label>
            <b-tooltip
              type="is-dark"
              size="is-large"
              multilined
              position="is-right"
              class="ml-3"
            >
              <template #content>
                <div class="text-xs text-left p-2">
                  {{ $t("MINIMUM_RESOLUTION") }}: 500x500px
                  <br />
                  <span class="whitespace-nowrap">
                    {{ $t("RECOMMENDED_RESOLUTION") }}: 1080x1080px
                  </span>
                  <br />
                  {{ $t("MAXIMUM_WEIGHT") }}: 5 Mo
                </div>
              </template>
              <fa :icon="['fas', 'question-circle']" />
            </b-tooltip>
          </template>
          <b-upload
            v-model="image"
            @input="handleImageFileInput"
            :loading="loading || $apollo.loading"
            :disabled="loading || $apollo.loading || disabled"
            accept="image/*"
            drag-drop
          >
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <fa
                    :icon="['fas', 'upload']"
                    size="4x"
                    class="text-gray-500"
                  />
                </p>
                <p>{{ $t("DROP_YOUR_IMAGE_HERE_OR_CLICK_TO_SELECT") }}</p>
              </div>
            </section>
          </b-upload>
        </b-field>
        <div v-show="!pristine && errors[0]" class="has-text-danger -mt-2">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
      <div v-if="imagePreview">
        <label class="label">{{ $t("IMAGE") }}</label>
        <div class="relative inline-block w-4/5">
          <img :src="imagePreview" />
          <div
            v-show="!disabled"
            class="opacity-0 hover:opacity-100 absolute inset-0 p-4 text-right"
          >
            <a @click="imageHash = ''" class="tag is-rounded is-delete"></a>
          </div>
        </div>
      </div>
    </ErrorMessage>

    <ValidationProvider
      class="block"
      :name="`&quot;${$t('CLICK_LINK')}&quot;`"
      :rules="{ required: true, url: { require_protocol: true } }"
      v-slot="{ flags: { pristine }, errors }"
      immediate
    >
      <b-field
        :label="$t('CLICK_LINK')"
        :type="!pristine && errors[0] ? 'is-danger' : ''"
      >
        <b-input
          v-model="link"
          placeholder="https://example.com"
          :disabled="disabled"
        ></b-input>
      </b-field>
      <div v-show="!pristine && errors[0]" class="has-text-danger -mt-2">
        {{ errors[0] }}
      </div>
    </ValidationProvider>

    <b-field :label="$t('BUTTON_TEXT')" class="mb-5">
      <b-select v-model="buttonName" expanded :disabled="disabled">
        <option
          v-for="btnTextItem in btnTextList"
          :key="btnTextItem"
          :value="btnTextItem"
        >
          {{ $t(btnTextItem) }}
        </option>
      </b-select>
    </b-field>

    <ValidationProvider
      class="block"
      :name="`&quot;${$t('MAIN_TEXT')}&quot;`"
      rules="required"
      v-slot="{ flags: { pristine }, errors }"
      immediate
    >
      <b-field :label="$t('MAIN_TEXT')">
        <b-input
          v-model="message"
          type="textarea"
          :disabled="disabled"
        ></b-input>
      </b-field>
      <div v-show="!pristine && errors[0]" class="has-text-danger -mt-2">
        {{ errors[0] }}
      </div>
    </ValidationProvider>

    <ValidationProvider
      class="block"
      :name="`&quot;${$t('DESCRIPTION')}&quot;`"
      rules="required"
      v-slot="{ flags: { pristine }, errors }"
      immediate
    >
      <b-field :label="$t('DESCRIPTION')">
        <b-input
          v-model="description"
          type="textarea"
          :disabled="disabled"
        ></b-input>
      </b-field>
      <div v-show="!pristine && errors[0]" class="has-text-danger -mt-2">
        {{ errors[0] }}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import { ValidationProvider } from "vee-validate";
import { error, warning } from "@/helpers/notification.js";
import adsImagesQuery from "./queries/adsImages.gql";
import uploadAdImageQuery from "./queries/uploadAdImage.gql";

const template = {
  name: "",
  imageHash: "",
  link: "",
  buttonName: "LEARN_MORE",
  description: "",
  message: ""
};

const btnTextList = [
  "SHOP_NOW",
  "LEARN_MORE",
  "GET_SHOWTIMES",
  "SIGN_UP",
  "DOWNLOAD",
  "WATCH_MORE",
  "CONTACT_US",
  "APPLY_NOW",
  "GET_QUOTE",
  "LISTEN_NOW",
  "GET_OFFER",
  "SUBSCRIBE"
];

export default {
  components: { ErrorMessage, ValidationProvider },
  props: {
    value: { type: Object, default: null },
    disabled: { type: Boolean, default: false }
  },
  data: ({ imageHash }) => ({
    loading: false,
    image: { imageHash }, // image: only used by vee-validate
    listImagesError: ""
  }),
  apollo: {
    adsImages: {
      query: adsImagesQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { actionId };
      },
      update: ({ listAdsImages }) => listAdsImages,
      error({ graphQLErrors, networkError }) {
        this.listImagesError = JSON.stringify(
          networkError || graphQLErrors[0].message
        );
      },
      skip() {
        return !this.imageHash;
      }
    }
  },
  computed: {
    btnTextList: () => btnTextList,
    imagePreview: ({ imageHash, adsImages }) => {
      if (!adsImages) return null;
      const adImage = adsImages.find(({ hash }) => hash === imageHash);

      return adImage ? adImage.url : null;
    },
    // v-model support: generate a setter/getter for each template property
    ...Object.entries(template).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          get: vm => (vm.value ? vm.value[key] : value),
          set(value) {
            this.$emit("input", { ...template, ...this.value, [key]: value });
          }
        }
      }),
      {}
    )
  },
  methods: {
    resetImage() {
      Object.assign(this, { image: null, imageHash: "" });
    },
    handleImageFileInput(file) {
      const image = new Image();
      image.onload = () => {
        const { height, width } = image;
        if (height < 500 || width < 500) {
          return warning(
            `${this.$t(
              "THE_RESOLUTION_OF_THIS_IMAGE_IS_TOO_LOW"
            )}<br />${this.$t("MINIMUM_RESOLUTION")}: 500x500px`
          );
        }

        const { size } = file;
        const fiveMb = 5242880;
        if (size > fiveMb) {
          return warning(
            `${this.$t("THE_WEIGHT_OF_THIS_IMAGE_IS_TOO_LARGE")}<br />${this.$t(
              "MAXIMUM_WEIGHT"
            )}: 5 Mo`
          );
        }

        this.uploadImageFile(file);
      };
      image.src = URL.createObjectURL(file);
    },
    uploadImageFile(image) {
      this.loading = true;
      const { actionId } = this.$route.params;

      this.$apollo
        .mutate({
          mutation: uploadAdImageQuery,
          variables: { actionId, image },
          refetchQueries: [{ query: adsImagesQuery, variables: { actionId } }]
        })
        .then(
          ({ data: { uploadAdImage } }) => (this.imageHash = uploadAdImage.hash)
        )
        .catch(({ graphQLErrors, networkError }) =>
          error(JSON.stringify(networkError || graphQLErrors[0].message))
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
