<template>
  <SummaryContainer
    :name="$t('SETTING')"
    :status="settingStatus"
    tab="initialization"
    :loading="$apollo.loading"
    :error="error"
  >
    <div
      v-show="settingStatus !== 'CONFIGURED'"
      class="text-center text-gray-500"
    >
      {{ $t("THIS_STEP_ISNT_CONFIGURED_YET") }}
    </div>
    <div v-show="settingStatus === 'CONFIGURED'">
      <div class="flex py-2">
        <div class="w-1/5 label">{{ $t("GOAL") }}</div>
        <div>{{ $t("TRAFFIC") }}</div>
      </div>
      <div class="flex py-2">
        <div class="w-1/5 label">{{ $t("PAGE") }}</div>
        <div>{{ pageName }}</div>
      </div>
      <div class="flex py-2">
        <div class="w-1/5 label">{{ $t("AD_ACCOUNT") }}</div>
        <div>{{ adAccountName }}</div>
      </div>
      <div class="flex py-2">
        <div class="w-1/5 label">{{ $t("DISTRIBUTION_PLATFORM") }}</div>
        <div
          class="grid gap-8 flex-grow"
          style="grid-template-columns: repeat(auto-fit, 350px);"
        >
          <FacebookSwitches :value="publisherPlatforms" readonly />
          <AudienceNetworkSwitches :value="publisherPlatforms" readonly />
          <InstagramSwitches
            v-if="instagramAvailable"
            :value="publisherPlatforms"
            readonly
          />
        </div>
      </div>
    </div>
  </SummaryContainer>
</template>

<script>
import SummaryContainer from "./SummaryContainer.vue";
import FacebookSwitches from "@/FacebookAction/SettingTab/components/FacebookSwitches.vue";
import AudienceNetworkSwitches from "@/FacebookAction/SettingTab/components/AudienceNetworkSwitches/AudienceNetworkSwitches.vue";
import InstagramSwitches from "@/FacebookAction/SettingTab/components/InstagramSwitches.vue";
import { get } from "lodash";
import { settingStatus } from "@/FacebookAction/SettingTab/SettingTab.vue";
import getFacebookConfigurationQuery from "@/FacebookAction/SettingTab/queries/getFacebookConfiguration.gql";
import actionSettingQuery from "@/FacebookAction/SettingTab/queries/actionSetting.gql";

export default {
  components: {
    SummaryContainer,
    FacebookSwitches,
    AudienceNetworkSwitches,
    InstagramSwitches
  },
  data: () => ({ action: {}, error: "" }),
  apollo: {
    facebookConfiguration: {
      query: getFacebookConfigurationQuery,
      update: ({ userMe: { FacebookConfigurations } }) =>
        FacebookConfigurations,
      error: ({ graphQLErrors, networkError }) =>
        (this.error = JSON.stringify(networkError || graphQLErrors))
    },
    action: {
      query: actionSettingQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { id: actionId };
      },
      update: ({ facebookAction }) => facebookAction,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: {
    settingStatus,
    instagramAvailable: ({
      action: { configuration } = {},
      facebookConfiguration: { pages = [] } = {}
    }) =>
      pages.find(({ id }) => id === configuration?.pageId)?.instagramActorId,
    pageName: ({ action: { configuration }, facebookConfiguration }) => {
      if (!facebookConfiguration || !configuration) return "";

      const { pages } = facebookConfiguration;
      const { pageId } = configuration;
      const page = pages.find(({ id }) => id === pageId);

      return page ? page.name : "unknown";
    },
    adAccountName: ({ action: { configuration }, facebookConfiguration }) => {
      if (!facebookConfiguration || !configuration) return "";

      const { adAccounts } = facebookConfiguration;
      const { adAccountId } = configuration;
      const adAccount = adAccounts.find(({ id }) => id === adAccountId);

      return adAccount ? adAccount.name : "unknown";
    },
    publisherPlatforms: ({ action: { configuration } }) => {
      const publisherPlatforms = get(
        configuration,
        ["targeting", "publisherPlatforms"],
        []
      );

      return {
        facebook: publisherPlatforms.includes("facebook"),
        audienceNetwork: publisherPlatforms.includes("audience_network"),
        instagram: publisherPlatforms.includes("instagram")
      };
    }
  }
};
</script>
