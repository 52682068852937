var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ErrorMessage',{attrs:{"error":_vm.error}},[_c('div',{staticClass:"flex"},[_c('ValidationProvider',{staticClass:"block w-1/2 mr-2",staticStyle:{"margin-bottom":"0"},attrs:{"name":("\"" + (_vm.$t('COUNTRY')) + "\""),"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.flags.pristine;
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('COUNTRY'),"type":!pristine && errors[0] ? 'is-danger' : ''}},[_c('b-taginput',{attrs:{"data":_vm.filteredCountries,"placeholder":_vm.$t('SEARCH'),"disabled":_vm.disabled,"field":"countryName","autocomplete":"","open-on-focus":"","attached":""},on:{"typing":function (input) { return (_vm.countriesSearch = input); },"remove":_vm.removeCountry},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var countryName = ref.option.countryName;
return [_vm._v(" "+_vm._s(countryName)+" ")]}}],null,true),model:{value:(_vm.target.countries),callback:function ($$v) {_vm.$set(_vm.target, "countries", $$v)},expression:"target.countries"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!pristine && errors[0]),expression:"!pristine && errors[0]"}],staticClass:"has-text-danger -mt-2 mb-2"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{staticClass:"block w-1/2 ml-2",staticStyle:{"margin-bottom":"0"},attrs:{"immediate":""}},[_c('b-field',{attrs:{"label":_vm.$t('CITY'),"type":_vm.citiesError ? 'is-danger' : ''}},[_c('b-taginput',{attrs:{"data":_vm.filteredCities,"placeholder":_vm.$t('SEARCH'),"loading":_vm.$apollo.queries.cities.loading,"disabled":_vm.disabled || !_vm.target.countries.length,"field":"name","autocomplete":"","open-on-focus":"","attached":""},on:{"typing":_vm.typingCity,"add":_vm.addCity},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var name = ref.option.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.target.cities),callback:function ($$v) {_vm.$set(_vm.target, "cities", $$v)},expression:"target.cities"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.citiesError),expression:"citiesError"}],staticClass:"has-text-danger -mt-2"},[_vm._v(" "+_vm._s(_vm.citiesError)+" ")])],1)],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var kmValidationField = ref.errors.kmValidationField;
return [_c('div',{staticClass:"flex items-center"},[_c('ValidationProvider',{attrs:{"immediate":""}},[_c('b-checkbox',{attrs:{"disabled":!_vm.target.cities.length},model:{value:(_vm.target.withRadius),callback:function ($$v) {_vm.$set(_vm.target, "withRadius", $$v)},expression:"target.withRadius"}},[_vm._v(" "+_vm._s(_vm.$t("EXPAND"))+" ")])],1),_c('ValidationProvider',{attrs:{"vid":"kmValidationField","rules":"min_value:18","name":"\"Km\"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.flags.pristine;
var errors = ref.errors;
return [_c('b-field',{staticClass:"mx-3",attrs:{"type":!pristine && errors[0] ? 'is-danger' : ''}},[_c('b-input',{staticClass:"w-40",attrs:{"type":"number","disabled":!_vm.target.withRadius},model:{value:(_vm.target.citiesRadius),callback:function ($$v) {_vm.$set(_vm.target, "citiesRadius", $$v)},expression:"target.citiesRadius"}}),_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-static",class:{ 'border-none': !_vm.target.withRadius }},[_vm._v(" Km ")])])],1)]}}],null,true)}),_vm._v(" "+_vm._s(_vm.$t("AROUND_CITIES"))+" ")],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(kmValidationField && kmValidationField[0]),expression:"kmValidationField && kmValidationField[0]"}],staticClass:"has-text-danger mt-1"},[_vm._v(" "+_vm._s(_vm.$t("FACEBOOK_LIMITS_THE_MINIMUM_RADIUS_TO_18KM"))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }