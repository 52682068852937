<template>
  <Loader :loading="$apollo.loading" class="card shadow-md">
    <ErrorMessage :error="error">
      <Overlay :visible="!loading && !error && !settingConfigured" class="p-5">
        <ValidationObserver v-slot="{ invalid, pristine, reset }">
          <div class="flex mb-5">
            <div class="flex-grow">
              <StepStatusTag :status="budgetStatus" />
            </div>
            <b-button
              :loading="loading"
              @click="save(reset)"
              :disabled="pristine || invalid"
              type="is-primary"
            >
              {{ $t("SAVE") }}
            </b-button>
          </div>

          <div class="flex">
            <div class="w-1/2 mr-4">
              <ValidationProvider
                v-for="({ labelKey, modelKey, placeholderKey, disabledStatus },
                index) in dateTimeRangeFields"
                :key="index"
                :vid="$t(labelKey)"
                class="block"
                :class="index ? 'my-5' : 'mb-5'"
                :name="`&quot;${$t(labelKey)}&quot;`"
                :rules="
                  `required${
                    index ? '|after:' + $t(dateTimeRangeFields[0].labelKey) : ''
                  }`
                "
                v-slot="{ flags: { pristine }, errors }"
                immediate
              >
                <b-field :label="$t(labelKey)">
                  <b-datetimepicker
                    append-to-body
                    :ref="`dtPicker_${index}`"
                    v-model="$data[modelKey]"
                    :placeholder="$t(placeholderKey)"
                    :disabled="disabledStatus.includes(action.status)"
                    :min-datetime="
                      index
                        ? $data[dateTimeRangeFields[0].modelKey] ||
                          oneHourFromNow
                        : oneHourFromNow
                    "
                    :max-datetime="sixMonthsFromNow"
                  >
                    <template slot="left">
                      <button
                        class="button is-light"
                        @click="$data[modelKey] = null"
                      >
                        <fa :icon="['fas', 'times']" size="lg" class="mr-2" />
                        {{ $t("CLEAR") }}
                      </button>
                    </template>
                    <template slot="right">
                      <button
                        class="button is-primary"
                        @click="() => $refs[`dtPicker_${index}`][0].toggle()"
                      >
                        <fa :icon="['fas', 'check']" size="lg" class="mr-2" />
                        {{ $t("VALIDATE") }}
                      </button>
                    </template>
                  </b-datetimepicker>
                </b-field>
                <div
                  v-show="!pristine && errors[0]"
                  class="has-text-danger -mt-2"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>

              <ValidationProvider
                class="block my-5"
                :name="`&quot;${$t('AMOUNT')}&quot;`"
                rules="required|min_value:1"
                v-slot="{ flags: { pristine }, errors }"
                immediate
              >
                <label class="label">{{ $t("AMOUNT") }}</label>
                <b-field>
                  <b-input
                    v-model="amount"
                    type="number"
                    :min="1"
                    expanded
                    :use-html5-validation="false"
                    :disabled="action.status === 'DONE'"
                  ></b-input>
                  <p class="control">
                    <span class="button is-static">€</span>
                  </p>
                </b-field>
                <div
                  v-show="!pristine && errors[0]"
                  class="has-text-danger -mt-2"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </div>
            <div class="w-1/2 ml-4 flex flex-col items-center justify-center">
              <b-message
                v-show="startDate && endDate && amount > 0"
                type="is-info w-full"
              >
                <div class="text-left">
                  {{
                    $t(
                      "YOU_WILL_SPENT_X_EUROS_TO_BROADCAST_THIS_ACTION_FROM_START_DATE_TO_END_DATE",
                      {
                        amount,
                        startDate: new Date(startDate).toLocaleDateString(),
                        endDate: new Date(endDate).toLocaleDateString()
                      }
                    )
                  }}
                </div>
                <div class="text-left pt-2">
                  {{
                    $t(
                      "THE_FACEBOOK_ALGORITHM_AIMS_TO_MAXIMIZE_THE_CLICK_COUNT_FOR_YOUR_BUDGET"
                    )
                  }}
                </div>
              </b-message>
              <EstimatedCoverageChart
                v-show="action.audience && action.audience.status === 'READY'"
                class="w-full"
                @updateAmount="newAmount => (amount = newAmount)"
              />
            </div>
          </div>
        </ValidationObserver>
        <template #overlay>
          <UnavailableStepMessage />
        </template>
      </Overlay>
    </ErrorMessage>
  </Loader>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import UnavailableStepMessage from "@/FacebookAction/components/UnavailableStepMessage.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import Loader from "@/components/Loader.vue";
import StepStatusTag from "@/FacebookAction/components/StepStatusTag";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { success, error } from "@/helpers/notification.js";
import budgetQuery from "./queries/budget.gql";
import setBudgetQuery from "./queries/setBudget.gql";
import updateBudgetQuery from "./queries/updateBudget.gql";
import EstimatedCoverageChart from "./components/EstimatedCoverageChart/EstimatedCoverageChart.vue";

export const budgetStatus = ({ action }) =>
  action?.configuration?.lifetimeBudget ? "CONFIGURED" : "NOT_CONFIGURED";

// To workaround an API issue (unable to update a startDate which is in the past)
// we are adding 6 months to start/endDate before sending them to API
const dateInSixMonths = (date = new Date()) =>
  new Date(new Date(date).setMonth(date.getMonth() + 6));

// Still for the same workaround, we remove the 6 previously added months
// until the action is validated (status !== DRAFT)
const dateSixMonthsAgo = (date = new Date()) =>
  new Date(new Date(date).setMonth(date.getMonth() - 6));

const dateTimeRangeFields = [
  {
    labelKey: "START",
    modelKey: "startDate",
    placeholderKey: "CHOOSE_A_START_DATE",
    disabledStatus: ["SCHEDULED", "EXECUTING", "DONE"]
  },
  {
    labelKey: "END",
    modelKey: "endDate",
    placeholderKey: "CHOOSE_AN_END_DATE",
    disabledStatus: ["DONE"]
  }
];

export default {
  components: {
    Overlay,
    UnavailableStepMessage,
    ErrorMessage,
    Loader,
    StepStatusTag,
    ValidationProvider,
    ValidationObserver,
    EstimatedCoverageChart
  },
  props: { formValidation: { type: Object, required: true } },
  data: () => ({
    startDate: null,
    endDate: null,
    amount: 350,
    loading: false,
    error: ""
  }),
  apollo: {
    action: {
      query: budgetQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { id: actionId };
      },
      update({ facebookAction, facebookAction: { status, configuration } }) {
        if (configuration && configuration.startTime) {
          const { startTime, endTime, lifetimeBudget } = configuration;
          Object.assign(this.$data, {
            startDate:
              status === "DRAFT"
                ? dateSixMonthsAgo(new Date(startTime))
                : new Date(startTime),
            endDate:
              status === "DRAFT"
                ? dateSixMonthsAgo(new Date(endTime))
                : new Date(endTime),
            amount: lifetimeBudget / 100
          });
        }
        return facebookAction;
      },
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: {
    budgetStatus,
    dateTimeRangeFields: () => dateTimeRangeFields,
    oneHourFromNow: () =>
      new Date(new Date().setHours(new Date().getHours() + 1)),
    sixMonthsFromNow: () => dateInSixMonths(),
    settingConfigured: ({ action = {} }) => !!action.configuration,
    isDirty: ({ formValidation: { dirty } }) => dirty,
    isValid: ({ formValidation: { valid } }) => valid
  },
  methods: {
    save(resetFormValidation = () => {}) {
      this.loading = true;
      const { startDate, endDate, amount, configuration } = this;
      const { actionId } = this.$route.params;

      return this.$apollo
        .mutate({
          mutation: configuration ? updateBudgetQuery : setBudgetQuery,
          variables: {
            actionId,
            budget: {
              startDate: dateInSixMonths(startDate).toISOString(),
              endDate: dateInSixMonths(endDate).toISOString(),
              lifetimeBudget: new String(amount * 100)
            }
          }
        })
        .then(() => {
          resetFormValidation();
          success(this.$t("SUCCESSFULLY_SAVED"));
        })
        .catch(({ graphQLErrors, networkError }) =>
          error(JSON.stringify(networkError || graphQLErrors[0].message))
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>
