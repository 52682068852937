import { render, staticRenderFns } from "./AgeRangeTarget.vue?vue&type=template&id=5aaeb098&scoped=true&"
import script from "./AgeRangeTarget.vue?vue&type=script&lang=js&"
export * from "./AgeRangeTarget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aaeb098",
  null
  
)

export default component.exports