<template>
  <span class="tag is-light is-large" :class="statusStyle.tagType">
    <fa class="mr-2" :icon="statusStyle.icon" :class="statusStyle.color" />
    <span class="text-base">
      {{ statusStyle.labelKey ? $t(statusStyle.labelKey) : status }}
    </span>
  </span>
</template>

<script>
import { ACTION_STEP_STATUS_STYLE } from "@/FacebookAction/action.const.js";

export default {
  props: {
    status: {
      type: String,
      default: "NOT_CONFIGURED",
      validator: status => !!ACTION_STEP_STATUS_STYLE[status]
    }
  },
  computed: {
    statusStyle: ({ status }) =>
      ACTION_STEP_STATUS_STYLE[status] || ACTION_STEP_STATUS_STYLE.UNKNOWN
  }
};
</script>

<style scoped></style>
