<template>
  <Loader :loading="$apollo.loading">
    <ErrorMessage :error="error">
      <Overlay
        :visible="!['EXECUTING', 'DONE'].includes(action.status)"
        class="card p-5 shadow-md"
      >
        <b-tabs v-model="tabIndex" class="facebook-action-tabs">
          <b-tab-item
            v-for="({ labelKey, component }, index) in tabs"
            :key="labelKey"
            :label="$t(labelKey)"
          >
            <template #header>
              <div
                class="py-2 px-4 text-sm font-semibold hover:text-activation transition-colors duration-500"
                :class="
                  index === tabIndex
                    ? 'border-b-2 border-activation'
                    : 'text-gray-600'
                "
              >
                {{ $t(labelKey) }}
              </div>
            </template>
            <component :is="component" />
          </b-tab-item>
        </b-tabs>

        <template #overlay>
          {{
            $t("RESULTS_WILL_BE_AVAILABLE_ONCE_THE_ACTION_HAS_BEEN_LAUNCHED")
          }}
          <div class="flex justify-center mt-4">
            <b-button
              type="is-primary"
              tag="router-link"
              :to="{
                name: 'facebookAction',
                params: { ...$route.params, tab: 'summary' }
              }"
            >
              {{ $t("SUMMARY") }}
            </b-button>
          </div>
        </template>
      </Overlay>
    </ErrorMessage>
  </Loader>
</template>

<script>
import Loader from "@/components/Loader.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import Overlay from "@/components/Overlay.vue";
import PerformanceInsights from "./components/PerformanceInsights/PerformanceInsights.vue";
import DemographyInsights from "./components/DemographyInsights/DemographyInsights.vue";
import PositioningInsights from "./components/PositioningInsights/PositioningInsights.vue";
import actionQuery from "./queries/action.gql";

export default {
  components: { Loader, ErrorMessage, Overlay },
  data: () => ({ tabIndex: 0, error: "" }),
  apollo: {
    action: {
      query: actionQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { id: actionId };
      },
      update: ({ facebookAction }) => facebookAction,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors);
      }
    }
  },
  computed: {
    tabs: () => [
      { labelKey: "PERFORMANCE", component: PerformanceInsights },
      { labelKey: "DEMOGRAPHY", component: DemographyInsights },
      { labelKey: "POSITIONING", component: PositioningInsights }
    ]
  }
};
</script>
