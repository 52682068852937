<template>
  <Loader :loading="$apollo.loading" class="card shadow-md">
    <ErrorMessage :error="error">
      <Overlay :visible="!loading && !error && !configuration" class="p-5">
        <ValidationObserver v-slot="{ invalid, pristine, reset }">
          <div class="flex mb-2 justify-between">
            <StepStatusTag :status="visualStatus" />
            <b-message
              v-show="visualStatus === 'PENDING_REVIEW'"
              type="is-info"
              class="field-message mx-4"
              style="margin-bottom: 0"
            >
              {{
                $t(
                  "CERTAIN_CONTENT_BEING_PROHIBITED_BY_FACEBOOK_AND_INSTAGRAM_YOUR_VISUALS_ARE_THEREFORE_SUBJECT_TO_VALIDATION_BEFORE_PUBLICATION"
                )
              }}
            </b-message>
            <b-button
              @click="save(reset)"
              type="is-primary"
              :loading="loading"
              :disabled="pristine || invalid"
            >
              {{ $t("SAVE") }}
            </b-button>
          </div>

          <div class="flex">
            <TemplateEditor
              v-model="template"
              :disabled="action.status === 'DONE'"
              class="w-1/2 py-4"
            />

            <div class="is-divider-vertical"></div>

            <TemplatePreview :template="template" class="w-1/2 py-4" />
          </div>
        </ValidationObserver>
        <template #overlay>
          <UnavailableStepMessage />
        </template>
      </Overlay>
    </ErrorMessage>
  </Loader>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import Loader from "@/components/Loader.vue";
import Overlay from "@/components/Overlay.vue";
import UnavailableStepMessage from "@/FacebookAction/components/UnavailableStepMessage.vue";
import StepStatusTag from "@/FacebookAction/components/StepStatusTag";
import { ValidationObserver } from "vee-validate";
import { success, error } from "@/helpers/notification.js";
import TemplateEditor from "./components/TemplateEditor/TemplateEditor.vue";
import TemplatePreview from "./components/TemplatePreview/TemplatePreview.vue";
import visualQuery from "./queries/visual.gql";
import createVisualQuery from "./queries/createVisual.gql";
import updateVisualQuery from "./queries/updateVisual.gql";

export const visualStatus = ({ action: { visual } = {} }) =>
  visual?.status ?? "NOT_CONFIGURED";

export default {
  components: {
    ErrorMessage,
    Loader,
    Overlay,
    UnavailableStepMessage,
    StepStatusTag,
    ValidationObserver,
    TemplateEditor,
    TemplatePreview
  },
  props: { formValidation: { type: Object, required: true } },
  data: () => ({
    template: null,
    configuration: null,
    loading: false,
    error: ""
  }),
  apollo: {
    action: {
      query: visualQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { actionId };
      },
      update({ facebookAction, facebookAction: { configuration, visual } }) {
        this.configuration = configuration;
        if (visual) this.template = visual;

        return facebookAction;
      },
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: {
    visualStatus,
    isDirty: ({ formValidation: { dirty } }) => dirty,
    isValid: ({ formValidation: { valid } }) => valid
  },
  methods: {
    save(resetFormValidation = () => {}) {
      this.loading = true;
      const { actionId } = this.$route.params;
      const {
        name,
        imageHash,
        link,
        buttonName,
        description,
        message
      } = this.template;

      return this.$apollo
        .mutate({
          mutation: this.action.visual ? updateVisualQuery : createVisualQuery,
          variables: {
            actionId,
            adInput: { name, imageHash, link, buttonName, description, message }
          }
        })
        .then(() => {
          resetFormValidation();
          success(this.$t("SUCCESSFULLY_SAVED"));
        })
        .catch(({ graphQLErrors, networkError }) =>
          error(JSON.stringify(networkError || graphQLErrors[0].message))
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
