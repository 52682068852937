<template>
  <Loader :loading="$apollo.loading">
    <ErrorMessage :error="error">
      <div class="pt-2 grid grid-flow-col grid-cols-3 grid-rows-2 gap-0.5">
        <div
          class="p-3 rounded-sm"
          :class="
            indicator === key
              ? 'has-background-primary text-white cursor-default'
              : 'kpi hover:text-white cursor-pointer'
          "
          v-for="(label, key) in indicators"
          :key="key"
          @click="indicator = key"
        >
          <div class="flex flex-col items-center">
            <div
              class="poppins"
              :class="indicator === key ? 'text-white' : 'cursor-pointer'"
            >
              {{ $t(label) }}
            </div>
            <span class="text-2xl">
              {{ kpis[key] }}
              <span v-show="['cpc', 'cpm', 'cpp'].includes(key)">€</span>
            </span>
          </div>
        </div>
      </div>
      <div class="pt-6">
        <VueApexCharts
          type="bar"
          height="300"
          :options="chartOptions"
          :series="chartSeries"
        />
      </div>
    </ErrorMessage>
  </Loader>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import Loader from "@/components/Loader.vue";
import VueApexCharts from "vue-apexcharts";
import resultsQuery from "./queries/results.gql";
import { get } from "lodash";

const publisherPlatforms = {
  facebook: "FACEBOOK",
  audience_network: "AUDIENCE_NETWORK",
  instagram: "INSTAGRAM"
};

const indicators = {
  clicks: "NUMBER_OF_CLICKS",
  cpc: "COST_PER_CLICK",
  impressions: "IMPRESSIONS",
  cpm: "COST_PER_MILLE",
  reach: "TOTAL_REACH",
  cpp: "COST_PER_PEOPLE"
};

export default {
  components: { ErrorMessage, Loader, VueApexCharts },
  data: () => ({ indicator: "clicks", error: "" }),
  apollo: {
    results: {
      query: resultsQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { actionId };
      },
      update: ({ facebookAction: { insight } }) => insight,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: {
    indicators: () => indicators,
    kpis: ({ results }) => get(results, ["summary"], {}),
    insights: ({ results }) => {
      // We ensure that all publisher platforms will be present
      // Unused one will be with all indicators at 0
      const initialInsights = Object.keys(publisherPlatforms).map(
        publisherPlatform => ({
          publisherPlatform,
          clicks: "0",
          cpc: "0",
          impressions: "0"
        })
      );
      const insights = get(results, ["insights"], []);

      return initialInsights.map(({ publisherPlatform, ...rest }) => ({
        publisherPlatform,
        ...rest,
        ...(insights.find(
          insight => insight.publisherPlatform === publisherPlatform
        ) || {})
      }));
    },
    chartOptions: ({ insights, $i18n }) => ({
      xaxis: {
        categories: [
          ...new Set(
            insights.map(({ publisherPlatform }) =>
              $i18n.t(publisherPlatforms[publisherPlatform])
            )
          )
        ]
      },
      yaxis: {
        labels: {
          formatter: value =>
            Number.isInteger(value) ? value : value.toFixed(6)
        }
      },
      chart: {
        animations: {
          speed: 250,
          animateGradually: { enabled: false },
          dynamicAnimation: { speed: 250 }
        }
      },
      plotOptions: { bar: { columnWidth: "33%" } }
    }),
    chartSeries: ({ insights, indicator, $i18n }) => [
      {
        name: $i18n.t(indicators[indicator]),
        data: insights.map(insight => insight[indicator])
      }
    ]
  }
};
</script>

<style scoped>
.kpi:hover {
  background-color: #8fc7b9;
}
</style>
