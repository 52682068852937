<template>
  <Loader :loading="$apollo.loading" class="card p-5 shadow-md">
    <ErrorMessage :error="error">
      <ValidationObserver
        v-slot="{ invalid, pristine, reset }"
        class="block space-y-6"
      >
        <div class="flex">
          <div class="flex-grow">
            <StepStatusTag :status="settingStatus" />
          </div>
          <b-button
            @click="save(reset)"
            :loading="loading"
            :disabled="
              (pristine && platformPristine) || invalid || platformInvalid
            "
            type="is-primary"
          >
            {{ $t("SAVE") }}
          </b-button>
        </div>

        <ValidationProvider
          class="block"
          :name="`&quot;${$t('PAGE')}&quot;`"
          rules="required"
          v-slot="{ flags: { pristine }, errors }"
          immediate
        >
          <FieldWithMessage :label="$t('PAGE')">
            <b-dropdown
              v-model="pageId"
              expanded
              :disabled="action.status === 'DONE'"
            >
              <b-button
                slot="trigger"
                expanded
                class="justify-between"
                icon-right="angle-down"
              >
                {{ selectedPage ? selectedPage.name : $t("SELECT_A_PAGE") }}
              </b-button>
              <b-dropdown-item
                v-for="{ id, name } in facebookConfiguration.pages"
                :key="id"
                :value="id"
              >
                {{ name }}
              </b-dropdown-item>
            </b-dropdown>
          </FieldWithMessage>
          <div v-show="!pristine && errors[0]" class="has-text-danger -mt-2">
            {{ errors[0] }}
          </div>
        </ValidationProvider>

        <ValidationProvider
          class="block"
          :name="`&quot;${$t('AD_ACCOUNT')}&quot;`"
          rules="required"
          v-slot="{ flags: { pristine }, errors }"
          immediate
        >
          <FieldWithMessage
            :label="$t('AD_ACCOUNT')"
            :tooltip="{
              label: `${$t(
                'GATHER_ALL_OF_YOUR_ACTIVITIES_AND_ALLOW_YOU_TO_MANAGE_YOUR_ADVERTISING_CAMPAIGNS_ON_THE_PLATFORM'
              )}. ${$t('IT_DIFFERS_FROM_THE_COMPANY_PAGE')}.`,
              multilined: true
            }"
          >
            <b-dropdown
              v-model="adAccountId"
              expanded
              :disabled="action.status === 'DONE'"
            >
              <b-button
                slot="trigger"
                expanded
                class="justify-between"
                icon-right="angle-down"
              >
                {{
                  selectedAdAccount
                    ? selectedAdAccount.name
                    : $t("SELECT_AN_AD_ACCOUNT")
                }}
              </b-button>
              <b-dropdown-item
                v-for="{
                  id,
                  name,
                  accountStatus
                } in facebookConfiguration.adAccounts"
                :key="id"
                :value="id"
                class="flex justify-between"
                :disabled="accountStatus !== '1'"
              >
                {{ name }}
                <b-tag
                  :type="accountStatus === '1' ? 'is-success' : 'is-warning'"
                >
                  {{ $t(adAccountStatusList[accountStatus]) }}
                </b-tag>
              </b-dropdown-item>
            </b-dropdown>
            <template v-if="!facebookConfiguration.adAccounts.length" #message>
              {{
                $t("IT_LOOKS_LIKE_YOU_DONT_HAVE_AN_ADVERTISING_ACCOUNT_YET")
              }}.
              <br />
              {{ $t("FIND_OUT_IN_OUR_DOCUMENTATION") }}
              <a
                target="_blank"
                href="https://delight-space.slite.com/p/note/27f7iSkMjf4YBYZyoeAf1f/Creer-compte-publicitaire"
                >{{ $t("HOW_TO_CREATE_AN_ADVERTISING_ACCOUNT") }}</a
              >.
            </template>
          </FieldWithMessage>
          <div v-show="!pristine && errors[0]" class="has-text-danger -mt-2">
            {{ errors[0] }}
          </div>
        </ValidationProvider>

        <div>
          <label class="label">{{ $t("DISTRIBUTION_PLATFORM") }}</label>
          <div class="text-gray-500 -mt-2 mb-5 w-1/2 pr-2">
            {{ $t("DISCOVER_THE_SPECIFICS_OF_EACH_PLATFORM_IN") }}
            <a
              target="_blank"
              href="https://delight-space.slite.com/p/note/TvkLwKczcs3nAvtmne3K3Y/Specifites-plateformes"
              class="text-blue-700 underline"
              >{{ $t("OUR_DOCUMENTATION") }}</a
            >.
          </div>

          <FacebookSwitches
            v-model="publisherPlatforms"
            @input="platformPristine = false"
            :disabled="action.status === 'DONE'"
            class="pb-3"
          />
          <AudienceNetworkSwitches
            v-model="publisherPlatforms"
            @input="platformPristine = false"
            :disabled="action.status === 'DONE'"
            class="py-3"
          />
          <InstagramSwitches
            v-model="publisherPlatforms"
            @input="platformPristine = false"
            :disabled="!instagramAvailable || action.status === 'DONE'"
            class="py-3"
          >
            <template #message v-if="!instagramAvailable">
              {{
                $t(
                  "YOU_HAVE_TO_LINK_YOUR_INSTAGRAM_ACCOUNT_TO_YOUR_FACEBOOK_PAGE_BEFORE_YOU_CAN_BROADCAST_ON_THIS_CHANNEL"
                )
              }}.
              <a
                target="_blank"
                href="https://delight-space.slite.com/p/note/8trWDEkg3hBMxq5JDsCpZX/Lier-Instagram-et-Facebook"
                >{{ $t("HOW_TO") }}</a
              >?
            </template>
          </InstagramSwitches>
          <div
            v-show="!platformPristine && platformInvalid"
            class="has-text-danger mt-1"
          >
            {{ $t("YOU_HAVE_TO_CHOOSE_AT_LEAST_ONE_DISTRIBUTION_PLATFORM") }}
          </div>
        </div>
      </ValidationObserver>
    </ErrorMessage>
  </Loader>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import Loader from "@/components/Loader.vue";
import StepStatusTag from "@/FacebookAction/components/StepStatusTag";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { success, error } from "@/helpers/notification.js";
import FieldWithMessage from "@/components/FieldWithMessage.vue";
import FacebookSwitches from "./components/FacebookSwitches.vue";
import AudienceNetworkSwitches from "./components/AudienceNetworkSwitches/AudienceNetworkSwitches.vue";
import InstagramSwitches from "./components/InstagramSwitches.vue";
import getFacebookConfigurationQuery from "./queries/getFacebookConfiguration.gql";
import actionSettingQuery from "./queries/actionSetting.gql";
import setActionSettingQuery from "./queries/setActionSetting.gql";
import budgetQuery from "@/FacebookAction/BudgetTab/queries/budget.gql";
import actionAudienceQuery from "@/FacebookAction/AudienceTab/queries/action.gql";
import { adAccountStatusList } from "../facebook.const.js";

export const settingStatus = ({ action }) =>
  action?.configuration?.adAccountId ? "CONFIGURED" : "NOT_CONFIGURED";

export default {
  components: {
    ErrorMessage,
    Loader,
    StepStatusTag,
    ValidationProvider,
    ValidationObserver,
    FieldWithMessage,
    FacebookSwitches,
    InstagramSwitches,
    AudienceNetworkSwitches
  },
  props: { formValidation: { type: Object, required: true } },
  data: () => ({
    pageId: "",
    adAccountId: "",
    publisherPlatforms: {
      facebook: false,
      audienceNetwork: false,
      instagram: false
    },
    platformPristine: true,
    facebookConfiguration: { pages: [], adAccounts: [] },
    loading: false,
    error: ""
  }),
  apollo: {
    facebookConfiguration: {
      query: getFacebookConfigurationQuery,
      update: ({ userMe: { FacebookConfigurations } }) =>
        FacebookConfigurations,
      error: ({ graphQLErrors, networkError }) =>
        (this.error = JSON.stringify(networkError || graphQLErrors))
    },
    action: {
      query: actionSettingQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { id: actionId };
      },
      update({ facebookAction, facebookAction: { configuration } }) {
        if (configuration) {
          const { pageId, adAccountId, targeting } = configuration;
          const { publisherPlatforms } = targeting;
          Object.assign(this.$data, {
            pageId,
            adAccountId,
            publisherPlatforms: {
              facebook: publisherPlatforms.includes("facebook"),
              audienceNetwork: publisherPlatforms.includes("audience_network"),
              instagram: publisherPlatforms.includes("instagram")
            }
          });
        }
        return facebookAction;
      },
      error: ({ graphQLErrors, networkError }) =>
        (this.error = JSON.stringify(networkError || graphQLErrors))
    }
  },
  computed: {
    settingStatus,
    platformInvalid: ({ publisherPlatforms }) =>
      !Object.values(publisherPlatforms).includes(true),
    instagramAvailable: ({ pageId, facebookConfiguration: { pages } }) =>
      pages.find(({ id }) => id === pageId)?.instagramActorId,
    selectedAdAccount: ({
      adAccountId,
      facebookConfiguration: { adAccounts = [] } = {}
    }) => adAccounts.find(({ id }) => id === adAccountId),
    selectedPage: ({ pageId, facebookConfiguration: { pages = [] } = {} }) =>
      pages.find(({ id }) => id === pageId),
    adAccountStatusList: () => adAccountStatusList,
    isDirty: ({ formValidation: { dirty }, platformPristine }) =>
      dirty || !platformPristine,
    isValid: ({ formValidation: { valid }, platformInvalid }) =>
      valid && !platformInvalid
  },
  methods: {
    save(resetFormValidation = () => {}) {
      this.loading = true;
      const { actionId } = this.$route.params;
      const { pageId, adAccountId } = this;
      const { facebook, audienceNetwork, instagram } = this.publisherPlatforms;

      return this.$apollo
        .mutate({
          mutation: setActionSettingQuery,
          variables: {
            actionId,
            configuration: {
              pageId,
              adAccountId,
              publisherPlatforms: [
                ...(facebook ? ["FACEBOOK"] : []),
                ...(audienceNetwork ? ["AUDIENCE_NETWORK"] : []),
                ...(instagram ? ["INSTAGRAM"] : [])
              ],
              objective: "LINK_CLICKS"
            }
          },
          refetchQueries: [
            { query: budgetQuery, variables: { id: actionId } },
            { query: actionAudienceQuery, variables: { actionId } }
          ]
        })
        .then(() => {
          resetFormValidation();
          this.platformPristine = true;
          success(this.$t("SUCCESSFULLY_SAVED"));
        })
        .catch(({ graphQLErrors, networkError }) =>
          error(JSON.stringify(networkError || graphQLErrors[0].message))
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style>
.field-message .message-body {
  @apply p-4;
}
</style>
