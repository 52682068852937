<template>
  <Loader :loading="$apollo.loading">
    <ErrorMessage :error="error">
      <div class="pt-2 grid grid-flow-col grid-cols-3 grid-rows-2 gap-0.5">
        <div
          class="p-3 rounded-sm"
          :class="
            indicator === key
              ? 'has-background-primary text-white cursor-default'
              : 'kpi hover:text-white cursor-pointer'
          "
          v-for="(label, key) in indicators"
          :key="key"
          @click="indicator = key"
        >
          <div class="flex flex-col items-center">
            <div
              class="poppins"
              :class="indicator === key ? 'text-white' : 'cursor-pointer'"
            >
              {{ $t(label) }}
            </div>
            <span class="text-2xl">
              {{ kpis[key] }}
              <span v-show="['cpc', 'cpm', 'cpp'].includes(key)">€</span>
            </span>
          </div>
        </div>
      </div>
      <div class="pt-6">
        <VueApexCharts
          type="line"
          height="300"
          :options="chartOptions"
          :series="chartSeries"
        />
      </div>
    </ErrorMessage>
  </Loader>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import Loader from "@/components/Loader.vue";
import VueApexCharts from "vue-apexcharts";
import resultsQuery from "./queries/results.gql";
import { get, random } from "lodash";

const previewPerf = ({ date, multiplicator = 1 }) => ({
  ...(date ? { dateStart: date } : {}),
  clicks: random(300) * multiplicator,
  impressions: random(9999) * multiplicator,
  cpc: random(0.08, 0.2).toFixed(6)
});

const previewPerfs = () => {
  const y = new Date().getFullYear();
  const m = new Date().getMonth();
  const perfs = [];

  for (let d = 0; d < 7; d++) {
    const date = new Date(y, m, d + 20);
    perfs.push(previewPerf({ date }));
  }

  return perfs;
};

const indicators = {
  clicks: "NUMBER_OF_CLICKS",
  cpc: "COST_PER_CLICK",
  impressions: "IMPRESSIONS",
  cpm: "COST_PER_MILLE",
  reach: "TOTAL_REACH",
  cpp: "COST_PER_PEOPLE"
};

export default {
  components: { ErrorMessage, Loader, VueApexCharts },
  data: () => ({ indicator: "clicks", error: "" }),
  apollo: {
    results: {
      query: resultsQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { actionId };
      },
      update: ({ facebookAction: { insight } }) => insight,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: {
    indicators: () => indicators,
    insights: ({ results }) => get(results, ["insights"], previewPerfs()),
    chartOptions: ({ insights }) => ({
      xaxis: {
        categories: insights.map(({ dateStart }) =>
          new Date(dateStart).toLocaleDateString()
        )
      },
      yaxis: {
        labels: {
          formatter: value =>
            Number.isInteger(value) ? value : value.toFixed(6)
        }
      },
      stroke: { curve: "smooth" },
      chart: {
        animations: {
          speed: 250,
          animateGradually: { enabled: false },
          dynamicAnimation: { speed: 250 }
        }
      }
    }),
    chartSeries: ({ insights, indicator, $i18n }) => [
      {
        name: $i18n.t(indicators[indicator]),
        data: insights.map(perf => perf[indicator])
      }
    ],
    kpis: ({ results }) =>
      results ? results.summary : previewPerf({ multiplicator: 7 })
  }
};
</script>

<style scoped>
.kpi:hover {
  background-color: #8fc7b9;
}
</style>
