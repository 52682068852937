<template>
  <Loader :loading="$apollo.loading" class="card shadow-md">
    <ErrorMessage :error="error">
      <Overlay :visible="waitingFacebookApi || !settingConfigured" class="p-5">
        <ValidationObserver v-slot="{ invalid, pristine, reset }">
          <div class="flex mb-5">
            <div class="flex-grow">
              <StepStatusTag :status="audienceStatus" />
            </div>
            <b-button
              type="is-primary"
              :loading="loading"
              :disabled="pristine || invalid || audienceLength < 100"
              @click="save(reset)"
            >
              {{ $t("SAVE") }}
            </b-button>
          </div>

          <div class="flex items-end mb-4">
            <ValidationProvider
              class="block w-1/2 mr-4"
              style="margin-bottom: 0"
              :name="`&quot;${$t('NAME')}&quot;`"
              rules="required"
              v-slot="{ flags: { pristine }, errors }"
              immediate
            >
              <b-field
                :label="$t('NAME')"
                :type="!pristine && errors[0] ? 'is-danger' : ''"
              >
                <b-input
                  v-model="name"
                  :disabled="action.status === 'DONE'"
                ></b-input>
              </b-field>
              <div
                v-show="!pristine && errors[0]"
                class="has-text-danger -mt-2"
              >
                {{ errors[0] }}
              </div>
            </ValidationProvider>
            <div class="w-1/2 ml-4 mb-3">
              <b-message
                v-show="!action.audience"
                type="is-info"
                class="field-message"
                style="margin-bottom: 0"
              >
                <div class="text-left">
                  {{ $t("CREATE_THIS_AUDIENCE_TO_BE_ABLE_TO_USE_IT") }}
                </div>
              </b-message>
              <b-message
                v-show="action.audience && action.audience.status === 'READY'"
                type="is-success"
                class="field-message"
                style="margin-bottom: 0"
              >
                <div class="text-left">
                  {{ $t("THIS_AUDIENCE_IS_READY_TO_BE_USED") }}
                </div>
              </b-message>
              <b-message
                v-show="action.audience && action.audience.status === 'ERROR'"
                type="is-danger"
                class="field-message"
                style="margin-bottom: 0"
              >
                <div
                  class="text-left"
                  v-html="
                    $t(
                      'THERE_IS_AN_ISSUE_WITH_THIS_AUDIENCE_PLEASE_CONTACT_OUR_SUPPORT'
                    )
                  "
                ></div>
              </b-message>
              <b-message
                v-show="
                  action.audience && action.audience.status === 'TOO_SMALL'
                "
                type="is-danger"
                class="field-message"
                style="margin-bottom: 0"
              >
                <div class="text-left">
                  {{ $t("SOURCE_AUDIENCE_DOESNT_CONTAINS_ENOUGH_CONTACTS") }}
                </div>
              </b-message>
              <b-message
                v-show="
                  action.audience &&
                    action.audience.status === 'TOO_SMALL_SAME_COUNTRY'
                "
                type="is-danger"
                class="field-message"
                style="margin-bottom: 0"
              >
                <div class="text-left">
                  {{
                    $t(
                      "SOURCE_AUDIENCE_DOESNT_CONTAINS_ENOUGH_CONTACTS_IN_THE_SELECTED_COUNTRY"
                    )
                  }}
                </div>
              </b-message>
            </div>
          </div>

          <div class="flex">
            <SourceForm
              class="w-1/2 py-8 pr-8 flex-shrink-0"
              :disabled="action.status === 'DONE'"
            />
            <TargetForm
              class="w-1/2 py-8 pl-8 border-l-2 border-gray-200 flex-shrink-0"
              :disabled="action.status === 'DONE'"
            />
          </div>
        </ValidationObserver>
        <template #overlay>
          <div v-show="waitingFacebookApi">
            <div class="mb-3">
              {{ $t("FINDING_YOUR_FUTURE_AUDIENCE") }}
            </div>
            <b-progress type="is-primary" size="is-small" />
            <div class="mt-4">
              {{ $t("THIS_MAY_TAKE_UP_TO_A_FEW_HOURS") }}
            </div>
          </div>
          <UnavailableStepMessage v-show="!settingConfigured" />
        </template>
      </Overlay>
    </ErrorMessage>
  </Loader>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import Loader from "@/components/Loader.vue";
import Overlay from "@/components/Overlay.vue";
import UnavailableStepMessage from "@/FacebookAction/components/UnavailableStepMessage.vue";
import StepStatusTag from "@/FacebookAction/components/StepStatusTag";
import TargetForm from "./components/TargetForm/TargetForm.vue";
import SourceForm from "./components/SourceForm/SourceForm.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { success, error } from "@/helpers/notification.js";
import actionQuery from "./queries/action.gql";
import createAudienceFromFileQuery from "./queries/createAudienceFromFile.gql";
import createAudienceFromBookmarksQuery from "./queries/createAudienceFromBookmarks.gql";
import upsetTargetingQuery from "./queries/upsetTargeting.gql";
import COUNTRIES from "./components/TargetForm/components/GeoLocationsTarget/countries.const.js";
import { mapState, mapActions } from "vuex";

export const audienceStatus = ({ action: { audience } = {} }) =>
  audience?.status ?? "NOT_CONFIGURED";

export default {
  components: {
    ErrorMessage,
    Loader,
    Overlay,
    UnavailableStepMessage,
    StepStatusTag,
    TargetForm,
    SourceForm,
    ValidationObserver,
    ValidationProvider
  },
  props: { formValidation: { type: Object, required: true } },
  data: () => ({
    name: "",
    source: {
      bookmarks: [],
      csvFile: null,
      actionResult: null,
      pristine: true
    },
    target: {
      countries: [COUNTRIES.find(({ countryCode }) => countryCode === "FR")],
      cities: [],
      withRadius: false,
      citiesRadius: 18,
      ageRange: [18, 65],
      gender: "ALL",
      interests: []
    },
    loading: false,
    error: "",
    action: {}
  }),
  provide() {
    return { audienceTab: this.$data };
  },
  apollo: {
    action: {
      query: actionQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { actionId };
      },
      update({ facebookAction, facebookAction: { audience, configuration } }) {
        if (!audience) return facebookAction;

        const { name, status, countSource, bookmarks, sourceFile } = audience;
        const {
          ageMax,
          ageMin,
          gender,
          geoLocations: { cities, countries: countriesCode },
          interests
        } = configuration.targeting;
        const countries = COUNTRIES.filter(({ countryCode }) =>
          [
            ...countriesCode,
            ...cities.map(({ countryCode }) => countryCode)
          ].includes(countryCode)
        );
        const target = {
          ...this.target,
          ageRange: [ageMin, ageMax],
          gender,
          cities,
          withRadius: !!cities[0]?.radius,
          citiesRadius: cities[0]?.radius ?? 18,
          countries,
          interests
        };
        const normalizedFile = new Blob();
        normalizedFile.name = sourceFile || "";

        Object.assign(this.$data, {
          name,
          target,
          source: {
            ...this.source,
            bookmarks,
            ...(sourceFile && {
              csvFile: { length: countSource, headers: [], normalizedFile }
            })
          }
        });

        // auto refresh the status every 60s when audience is creating
        if (status === "CREATING") {
          this.$apollo.queries.action.startPolling(60000);
        } else {
          this.$apollo.queries.action.stopPolling();
        }

        return facebookAction;
      },
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: {
    ...mapState({
      totalAudience: ({ qlik: { totalAudience } }) => totalAudience
    }),
    audienceStatus,
    waitingFacebookApi: ({ action: { audience } }) =>
      audience?.status === "CREATING",
    settingConfigured: ({ action }) => !!action.configuration,
    audienceLength: ({
      totalAudience,
      source: { csvFile, actionResult },
      action
    }) => {
      if (action.audience) return action.audience.countSource;

      const csvFileLength = csvFile ? csvFile.length : 0;
      const actionResultLength = actionResult ? actionResult.length : 0;
      return totalAudience + csvFileLength + actionResultLength;
    },
    isDirty: ({ formValidation: { dirty } }) => dirty,
    isValid: ({ formValidation: { valid }, audienceLength }) =>
      valid && audienceLength > 99
  },
  methods: {
    ...mapActions(["exportAudienceData"]),
    async save(resetFormValidation = () => {}) {
      this.loading = true;
      const { name, exportAudienceData } = this;
      const { actionId } = this.$route.params;
      const {
        ageRange,
        gender,
        cities,
        countries,
        withRadius,
        citiesRadius,
        interests
      } = this.target;
      const [ageMin, ageMax] = ageRange;

      let mutation;
      let variables = {
        actionId,
        audience: { name },
        lookalikeCountriesCodes: [
          ...new Set([
            ...countries.map(({ countryCode }) => countryCode),
            ...cities.map(({ countryCode }) => countryCode)
          ])
        ]
      };
      const { pristine, csvFile, bookmarks } = this.source;

      if (!pristine) {
        if (csvFile) {
          const {
            delimiter: separator,
            emailHeader,
            normalizedFile: file
          } = csvFile;

          variables = {
            ...variables,
            file: { file, fileFormat: "CSV", separator, emailHeader }
          };
          mutation = createAudienceFromFileQuery;
        }

        if (bookmarks.length) {
          const bookmarkIds = bookmarks.map(({ id }) => id);
          const fileUrl = await exportAudienceData(bookmarkIds).catch(err => {
            error(err);
            throw new Error(err);
          });

          variables = {
            ...variables,
            bookmarks: {
              bookmarks: bookmarks.map(({ name, qlikId }) => ({
                name,
                qlikId
              })),
              fileUrl,
              fileFormat: "CSV",
              separator: ",",
              emailHeader: "Email du spectateur"
            }
          };
          mutation = createAudienceFromBookmarksQuery;
        }
      }

      return Promise.all([
        ...(mutation
          ? [
              this.$apollo.mutate({
                mutation,
                variables,
                refetchQueries: [
                  { query: actionQuery, variables: { actionId } }
                ]
              })
            ]
          : []),
        this.$apollo.mutate({
          mutation: upsetTargetingQuery,
          variables: {
            actionId,
            target: {
              ageMin,
              ageMax,
              gender,
              interests: interests.map(({ __typename, ...rest }) => rest),
              geoLocations: {
                countries: countries
                  .filter(
                    ({ countryCode }) =>
                      !cities.some(city => city.countryCode === countryCode)
                  )
                  .map(({ countryCode }) => countryCode),
                cities: cities.map(({ key }) => ({
                  key,
                  ...(withRadius && {
                    distanceUnit: "KILOMETER",
                    radius: +citiesRadius
                  })
                }))
              }
            }
          }
        })
      ])
        .then(() => {
          resetFormValidation();
          success(this.$t("SUCCESSFULLY_SAVED"));
        })
        .catch(({ graphQLErrors, networkError }) =>
          error(JSON.stringify(networkError || graphQLErrors[0].message))
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
