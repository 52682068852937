var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Loader',{attrs:{"loading":_vm.$apollo.loading}},[_c('ErrorMessage',{attrs:{"error":_vm.error}},[_c('Overlay',{staticClass:"card p-5 shadow-md",attrs:{"visible":!['EXECUTING', 'DONE'].includes(_vm.action.status)},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("RESULTS_WILL_BE_AVAILABLE_ONCE_THE_ACTION_HAS_BEEN_LAUNCHED"))+" "),_c('div',{staticClass:"flex justify-center mt-4"},[_c('b-button',{attrs:{"type":"is-primary","tag":"router-link","to":{
              name: 'facebookAction',
              params: Object.assign({}, _vm.$route.params, {tab: 'summary'})
            }}},[_vm._v(" "+_vm._s(_vm.$t("SUMMARY"))+" ")])],1)]},proxy:true}])},[_c('b-tabs',{staticClass:"facebook-action-tabs",model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.tabs),function(ref,index){
            var labelKey = ref.labelKey;
            var component = ref.component;
return _c('b-tab-item',{key:labelKey,attrs:{"label":_vm.$t(labelKey)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"py-2 px-4 text-sm font-semibold hover:text-activation transition-colors duration-500",class:index === _vm.tabIndex
                  ? 'border-b-2 border-activation'
                  : 'text-gray-600'},[_vm._v(" "+_vm._s(_vm.$t(labelKey))+" ")])]},proxy:true}],null,true)},[_c(component,{tag:"component"})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }