var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-collapse',{staticClass:"card overflow-visible",attrs:{"open":false},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var open = ref.open;
return [_c('div',{staticClass:"card-header",attrs:{"role":"button"}},[_c('p',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t("IGNITION_BOOKMARKS"))+" "),_c('b-tag',{staticClass:"source-sans-pro font-normal ml-2",attrs:{"type":"is-info"}},[_c('fa',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTotalContactsLoading),expression:"isTotalContactsLoading"}],attrs:{"icon":['fas', 'spinner'],"spin":""}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isTotalContactsLoading),expression:"!isTotalContactsLoading"}]},[_vm._v(" "+_vm._s(_vm.totalAudience || (_vm.source.bookmarks[0] ? _vm.audienceLength : 0))+" ")])],1)],1),_c('a',{staticClass:"card-header-icon"},[_c('fa',{attrs:{"icon":open ? ['fas', 'caret-down'] : ['fas', 'caret-up']}})],1)])]}}])},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('b-field',[_c('b-taginput',{attrs:{"data":_vm.filteredBookmarks,"field":"name","placeholder":_vm.$t('SEARCH'),"autocomplete":"","open-on-focus":"","attached":"","loading":_vm.bookmarksRequestLoading,"disabled":_vm.disabled || _vm.bookmarksRequestLoading || !!_vm.source.csvFile},on:{"input":function (value) { return _vm.getAudienceInfo(value.map(function (ref) {
	var id = ref.id;

	return id;
	})); },"typing":function (input) { return (_vm.search = input); },"add":_vm.addBookmark},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_option = ref.option;
var name = ref_option.name;
var combined = ref_option.combined; if ( combined === void 0 ) combined = false;
var modifiedDate = ref_option.modifiedDate;
var author = ref_option.author;
var tags = ref_option.tags; if ( tags === void 0 ) tags = [];
return [_c('div',{staticClass:"text-base"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex-shrink-0 w-6 text-center"},[_c('b-tooltip',{attrs:{"active":combined,"type":"is-dark","label":"Issu d'une combinaison de marque-pages","position":"is-right"}},[_c('span',[_c('fa',{staticClass:"text-gray-500",class:{ '-mt-2': combined },attrs:{"icon":['fal', 'bookmark'],"size":"lg","fixed-width":""}}),_c('fa',{directives:[{name:"show",rawName:"v-show",value:(combined),expression:"combined"}],staticClass:"-ml-8 -mb-2 text-blue-400",attrs:{"icon":['fas', 'bookmark'],"size":"lg","fixed-width":""}})],1)])],1),_c('div',{staticClass:"flex-grow text-gray-800 text-left pl-4"},[_c('div',[_vm._v(_vm._s(name))]),_c('div',{staticClass:"text-sm text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("toLocaleString")(modifiedDate))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(author),expression:"author"}]},[_vm._v(" | "+_vm._s(author))])]),_c('div',{staticClass:"flex flex-wrap -ml-1"},_vm._l((tags),function(tag){return _c('span',{key:tag,staticClass:"tag is-primary is-light ml-1 mt-1"},[_c('fa',{staticClass:"mr-1",attrs:{"icon":['fas', 'tag']}}),_vm._v(" "+_vm._s(tag)+" ")],1)}),0)])])])]}}]),model:{value:(_vm.source.bookmarks),callback:function ($$v) {_vm.$set(_vm.source, "bookmarks", $$v)},expression:"source.bookmarks"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }