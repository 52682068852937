<template>
  <ValidationObserver ref="targetFormObserver" v-slot="{ invalid }" tag="div">
    <div class="flex">
      <h2 class="flex-grow title is-size-4">
        {{ $t("AUDIENCE_REFINEMENT") }}
      </h2>
      <b-tag type="is-info">
        <div class="text-base pb-1">
          {{ `${$t("ESTIMATED_COVERAGE")}: ` }}
          <fa v-show="loading" :icon="['fas', 'spinner']" spin />
          <span v-show="invalid">0</span>
          <span v-show="!loading && !invalid">
            {{ reachEstimate.toLocaleString() }}
          </span>
        </div>
      </b-tag>
    </div>

    <div class="space-y-6">
      <GeoLocationsTarget :disabled="disabled" />
      <AgeRangeTarget :disabled="disabled" />
      <GenderTarget :disabled="disabled" />
      <InterestsTarget :disabled="disabled" />
    </div>
  </ValidationObserver>
</template>

<script>
import GeoLocationsTarget from "./components/GeoLocationsTarget/GeoLocationsTarget.vue";
import AgeRangeTarget from "./components/AgeRangeTarget.vue";
import GenderTarget, { genders } from "./components/GenderTarget.vue";
import InterestsTarget from "./components/InterestsTarget/InterestsTarget.vue";
import { ValidationObserver } from "vee-validate";
import { debounce } from "lodash";
import { get as fbGet } from "@/helpers/facebookApi.js";
import { error } from "@/helpers/notification.js";

export default {
  components: {
    GeoLocationsTarget,
    AgeRangeTarget,
    GenderTarget,
    InterestsTarget,
    ValidationObserver
  },
  props: { disabled: { type: Boolean, default: false } },
  data: () => ({ loading: false, reachEstimate: 0 }),
  inject: ["audienceTab"],
  mounted() {
    this.getReachEstimate();
  },
  computed: {
    debouncedGetReachEstimate: ({ getReachEstimate }) =>
      debounce(getReachEstimate, 500)
  },
  methods: {
    async getReachEstimate() {
      const targetFormValid = await this.$refs?.targetFormObserver?.validate();
      if (!this.audienceTab.action.audience || !targetFormValid) return;

      this.loading = true;
      const { action, target } = this.audienceTab;
      const { lookalikeId } = action.audience;
      const { adAccountId, targeting } = action.configuration;
      const { publisherPlatforms } = targeting;
      const {
        countries,
        cities,
        withRadius,
        citiesRadius,
        ageRange: [ageMin, ageMax],
        gender,
        interests
      } = target;

      fbGet({
        url: `/${adAccountId}/reachestimate`,
        params: {
          targeting_spec: {
            age_min: ageMin,
            age_max: ageMax,
            interests: interests.map(({ __typename, ...rest }) => rest),
            ...(gender === "ALL" || {
              genders: [genders.findIndex(({ value }) => value === gender)]
            }),
            geo_locations: {
              countries: countries
                .filter(
                  ({ countryCode }) =>
                    !cities.some(city => city.countryCode === countryCode)
                )
                .map(({ countryCode }) => countryCode),
              cities: cities.map(({ key }) => ({
                key,
                ...(withRadius && {
                  distance_unit: "kilometer",
                  radius: +citiesRadius
                })
              }))
            },
            publisher_platforms: publisherPlatforms.map(publisherPlatform =>
              publisherPlatform.toLowerCase()
            ),
            ...(lookalikeId && { custom_audiences: [lookalikeId] })
          }
        }
      })
        .then(({ data: { data: { users } = {} } }) => {
          this.reachEstimate = users;
        })
        .catch(({ response: { data: { error: err } } }) =>
          error(JSON.stringify(err, undefined, 2))
        )
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    "audienceTab.target": {
      handler: "debouncedGetReachEstimate",
      deep: true
    }
  }
};
</script>

<style scoped></style>
