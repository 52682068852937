<template>
  <div>
    {{
      $t(
        "THIS_STEP_WILL_ONLY_BE_ACCESSIBLE_ONCE_THE_CONFIGURATION_STEP_HAS_BEEN_COMPLETED"
      )
    }}
    <div class="flex justify-center mt-4">
      <b-button
        type="is-primary"
        tag="router-link"
        :to="{
          name: 'facebookAction',
          params: { ...$route.params, tab: 'initialization' }
        }"
      >
        {{ $t("INITIALIZATION") }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped></style>
