<template>
  <div class="flex flex-col">
    <div class="flex items-center mb-6" v-if="!previewOnly">
      <h2 class="flex-grow title is-size-4">{{ $t("PREVIEW") }}</h2>
      <b-field class="-mt-3" v-show="previewFormats.length">
        <b-select
          v-model="previewFormat"
          @input="getAdPreview"
          :disabled="!previewAvailable"
        >
          <option
            v-for="{ labelKey, format } in previewFormats"
            :key="format"
            :value="format"
          >
            {{ $t(labelKey) }}
          </option>
        </b-select>
      </b-field>
    </div>

    <b-message
      v-show="template && template.status === 'DISAPPROVED'"
      type="is-danger"
      class="field-message"
    >
      <div class="text-left">
        {{ $t("THIS_VISUAL_DOESNT_COMPLY_WITH_FACEBOOK_ADVERTISING_POLICES") }}
      </div>
    </b-message>

    <div
      v-show="!previewAvailable"
      class="flex items-center justify-center flex-grow"
    >
      <div class="flex items-end">
        <fa :icon="['fas', 'long-arrow-left']" size="lg" class="mr-6" />
        {{ $t("START_BY_CONFIGURING_ALL_THE_FIELDS_OF_YOUR_VISUAL") }}
      </div>
    </div>

    <Loader
      v-show="previewAvailable"
      :loading="$apollo.loading || loading"
      class="flex-grow"
    >
      <ErrorMessage :error="error" class="flex justify-center">
        <b-message v-if="previewBlocked">
          {{ $t("PREVIEW_SEEMS_BLOCKED_BY_YOUR_AD_BLOCKER") }}.
        </b-message>
        <iframe
          v-else
          @load="previewLoaded"
          v-bind="previewAdIframe"
          scrolling="no"
        ></iframe>
      </ErrorMessage>
    </Loader>
  </div>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import Loader from "@/components/Loader.vue";
import actionConfigurationQuery from "./queries/actionConfiguration.gql";
import getFacebookConfigurationQuery from "@/FacebookAction/SettingTab/queries/getFacebookConfiguration.gql";
import { get as fbGet } from "@/helpers/facebookApi.js";
import { debounce } from "lodash";

const previewFormats = [
  {
    labelKey: "FACEBOOK_DESKTOP_FEED",
    format: "DESKTOP_FEED_STANDARD",
    publisherPlatform: "facebook",
    height: 710,
    width: 502
  },
  {
    labelKey: "FACEBOOK_STORY_MOBILE",
    format: "FACEBOOK_STORY_MOBILE",
    publisherPlatform: "facebook",
    height: 568,
    width: 320
  },
  {
    labelKey: "INSTAGRAM_STANDARD",
    format: "INSTAGRAM_STANDARD",
    publisherPlatform: "instagram"
  },
  {
    labelKey: "INSTAGRAM_STORY",
    format: "INSTAGRAM_STORY",
    publisherPlatform: "instagram"
  }
];

export const getPreviewAdCreativeAttrs = (previewFormat, previewAdCreative) => {
  const { height, width } = previewFormats.find(
    ({ format }) => format === previewFormat
  );

  // TODO: discover best height/width for Instagram preview
  // For now, we fallback on FB provided values (+30px to avoid scrolling)
  return {
    src: previewAdCreative.match(/https:[^"]+/)[0].replace(/&amp;/g, "&"),
    height: height || +previewAdCreative.match(/height="([0-9]+)"/)[1] + 30,
    width: width || +previewAdCreative.match(/width="([0-9]+)"/)[1]
  };
};

export default {
  components: { ErrorMessage, Loader },
  props: {
    template: Object,
    previewOnly: { type: Boolean, default: false }
  },
  data: () => ({
    previewFormat: previewFormats[0].format,
    previewAdIframe: {},
    previewTimeout: 0, // used to detect a blocked iframe
    previewBlocked: false, // used to display a message in case of blocked iframe
    loading: true,
    error: ""
  }),
  apollo: {
    facebookConfiguration: {
      query: getFacebookConfigurationQuery,
      update: ({ userMe: { FacebookConfigurations } }) =>
        FacebookConfigurations,
      error: ({ graphQLErrors, networkError }) =>
        (this.error = JSON.stringify(networkError || graphQLErrors))
    },
    actionConfiguration: {
      query: actionConfigurationQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { id: actionId };
      },
      update({ facebookAction: { configuration } }) {
        this.loading = true;
        this.debouncedGetAdPreview();
        return configuration;
      },
      error: ({ graphQLErrors, networkError }) =>
        (this.error = JSON.stringify(networkError || graphQLErrors)),
      skip() {
        return !this.facebookConfiguration || !this.previewAvailable;
      }
    }
  },
  computed: {
    previewFormats: ({
      actionConfiguration: { targeting: { publisherPlatforms = [] } = {} } = {}
    }) =>
      previewFormats.filter(({ publisherPlatform }) =>
        publisherPlatforms.includes(publisherPlatform)
      ),
    previewAvailable: ({ template }) =>
      template && Object.values(template).every(Boolean),
    debouncedGetAdPreview: ({ getAdPreview }) => debounce(getAdPreview, 500)
  },
  methods: {
    previewLoaded() {
      clearTimeout(this.previewTimeout);
    },
    getAdPreview() {
      this.loading = true;
      const { adAccountId, pageId } = this.actionConfiguration;
      const { pages } = this.facebookConfiguration;
      const instagram_actor_id = pages.find(({ id }) => id === pageId)
        ?.instagramActorId;
      const { previewFormat } = this;
      const {
        __typename,
        status: _status,
        imageHash,
        buttonName,
        ...rest
      } = this.template;

      fbGet({
        url: `/${adAccountId}/generatepreviews`,
        params: {
          ad_format: previewFormat,
          creative: {
            object_story_spec: {
              page_id: pageId,
              ...(instagram_actor_id && { instagram_actor_id }),
              link_data: {
                ...rest,
                image_hash: imageHash,
                call_to_action: { type: buttonName }
              }
            }
          }
        }
      })
        .then(({ data: { data } }) => {
          clearTimeout(this.previewTimeout);
          Object.assign(this.$data, {
            previewAdIframe: getPreviewAdCreativeAttrs(
              previewFormat,
              data[0].body
            ),
            previewBlocked: false,
            previewTimeout: setTimeout(
              () => (this.previewBlocked = true),
              10000
            )
          });
        })
        .catch(err => (this.error = JSON.stringify(err, undefined, 2)))
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    template() {
      if (this.previewAvailable) {
        this.loading = true;
        this.debouncedGetAdPreview();
      }
    }
  }
};
</script>

<style scoped></style>
