<template>
  <div>
    <b-field class="file">
      <b-upload @input="handleFileCHange" accept=".csv" :disabled="disabled">
        <a class="button is-primary" :disabled="disabled">
          <fa :icon="['fas', 'upload']" class="mr-2" />
          {{ $t("CHOOSE_A_FILE") }}...
        </a>
      </b-upload>
      <span
        class="file-name flex"
        :class="csvFile.rawFile ? 'has-text-dark' : 'has-text-grey-light'"
      >
        <fa
          v-show="csvFile.rawFile"
          :icon="['fal', 'file-csv']"
          size="lg"
          class="text-green-600 mr-2"
        />
        <span :class="{ 'mr-2': csvFile.rawFile }" class="truncate">
          {{ csvFile.rawFile ? csvFile.rawFile.name : $t("NO_FILE_CHOOSEN") }}
        </span>
        <a
          v-show="csvFile.rawFile"
          @click="$emit('input', null)"
          class="tag is-rounded is-delete flex-shrink-0"
        ></a>
      </span>
    </b-field>
    <div class="field mt-6 mb-4" v-show="csvFile.headers.length > 1">
      <label class="label">{{ $t("CSV_HEADER") }}</label>
      <div class="control">
        <div class="select">
          <select
            @change="handleEmailHeaderChange($event.target.value)"
            v-model="csvFile.emailHeader"
            :disabled="disabled"
          >
            <option value="" disabled>{{ $t("CHOOSE_HEADER") }}</option>
            <option v-for="(header, index) in csvFile.headers" :key="index">
              {{ header }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";
import { getNormalizedCsvFile } from "@/helpers/csv.js";

const getDefaultCsvFile = () => ({
  rawFile: null,
  normalizedFile: null,
  headers: [],
  emailHeader: "",
  length: 0,
  delimiter: null,
  valid: false
});

const sizeValidator = (value, min, max) => value >= min && value <= max;

export default {
  props: {
    value: { type: Object },
    disabled: { type: Boolean, default: false },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 200000 },
    showHelp: { type: Boolean, default: true }
  },
  computed: { csvFile: ({ value }) => value || getDefaultCsvFile() },
  methods: {
    handleFileCHange(file) {
      if (file) {
        this.$emit("input", { ...getDefaultCsvFile(), rawFile: file });
        Papa.parse(file, {
          header: true,
          skipEmptyLines: "greedy",
          complete: ({ data, meta: { fields: headers, delimiter } }) => {
            if (data.length) {
              const { name } = file;
              const { value, min, max } = this;
              const valid = headers[0] && sizeValidator(data.length, min, max);

              this.$emit("input", {
                ...value,
                normalizedFile: getNormalizedCsvFile(name, headers[0], data),
                headers: headers,
                rows: data,
                delimiter,
                length: data.length,
                emailHeader: headers[0] || "",
                valid
              });
            }
          }
        });
      }
    },
    handleEmailHeaderChange(emailHeader) {
      const { value, value: { rows = [] } = {}, min, max } = this;
      const { name } = value.rawFile;
      const valid = sizeValidator(value.length, min, max);
      this.$emit("input", {
        ...value,
        emailHeader,
        normalizedFile: getNormalizedCsvFile(name, emailHeader, rows),
        valid
      });
    }
  }
};
</script>

<style scoped></style>
