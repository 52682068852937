// We use a static list of countries because Facebook forbid to list all of them

// This list come from https://restcountries.eu/
// We don't use directly restcountries.eu API to avoid external dependency

export default [
  { countryCode: "AF", countryName: "Afghanistan" },
  { countryCode: "AX", countryName: "Åland" },
  { countryCode: "AL", countryName: "Albanie" },
  { countryCode: "DZ", countryName: "Algérie" },
  { countryCode: "AS", countryName: "Samoa américaines" },
  { countryCode: "AD", countryName: "Andorre" },
  { countryCode: "AO", countryName: "Angola" },
  { countryCode: "AI", countryName: "Anguilla" },
  { countryCode: "AQ", countryName: "Antarctique" },
  { countryCode: "AG", countryName: "Antigua-et-Barbuda" },
  { countryCode: "AR", countryName: "Argentine" },
  { countryCode: "AM", countryName: "Arménie" },
  { countryCode: "AW", countryName: "Aruba" },
  { countryCode: "AU", countryName: "Australie" },
  { countryCode: "AT", countryName: "Autriche" },
  { countryCode: "AZ", countryName: "Azerbaïdjan" },
  { countryCode: "BS", countryName: "Bahamas" },
  { countryCode: "BH", countryName: "Bahreïn" },
  { countryCode: "BD", countryName: "Bangladesh" },
  { countryCode: "BB", countryName: "Barbade" },
  { countryCode: "BY", countryName: "Biélorussie" },
  { countryCode: "BE", countryName: "Belgique" },
  { countryCode: "BZ", countryName: "Belize" },
  { countryCode: "BJ", countryName: "Bénin" },
  { countryCode: "BM", countryName: "Bermudes" },
  { countryCode: "BT", countryName: "Bhoutan" },
  { countryCode: "BO", countryName: "Bolivie" },
  { countryCode: "BQ", countryName: "Bonaire, Saint-Eustache et Saba" },
  { countryCode: "BA", countryName: "Bosnie-Herzégovine" },
  { countryCode: "BW", countryName: "Botswana" },
  { countryCode: "BV", countryName: "Île Bouvet" },
  { countryCode: "BR", countryName: "Brésil" },
  {
    countryCode: "IO",
    countryName: "Territoire britannique de l'océan Indien"
  },
  { countryCode: "UM", countryName: "Îles mineures éloignées des États-Unis" },
  { countryCode: "VG", countryName: "Îles Vierges britanniques" },
  { countryCode: "VI", countryName: "Îles Vierges des États-Unis" },
  { countryCode: "BN", countryName: "Brunei" },
  { countryCode: "BG", countryName: "Bulgarie" },
  { countryCode: "BF", countryName: "Burkina Faso" },
  { countryCode: "BI", countryName: "Burundi" },
  { countryCode: "KH", countryName: "Cambodge" },
  { countryCode: "CM", countryName: "Cameroun" },
  { countryCode: "CA", countryName: "Canada" },
  { countryCode: "CV", countryName: "Cap Vert" },
  { countryCode: "KY", countryName: "Îles Caïmans" },
  { countryCode: "CF", countryName: "République centrafricaine" },
  { countryCode: "TD", countryName: "Tchad" },
  { countryCode: "CL", countryName: "Chili" },
  { countryCode: "CN", countryName: "Chine" },
  { countryCode: "CX", countryName: "Île Christmas" },
  { countryCode: "CC", countryName: "Îles Cocos" },
  { countryCode: "CO", countryName: "Colombie" },
  { countryCode: "KM", countryName: "Comores" },
  { countryCode: "CG", countryName: "Congo" },
  { countryCode: "CD", countryName: "Congo (Rép. dém.)" },
  { countryCode: "CK", countryName: "Îles Cook" },
  { countryCode: "CR", countryName: "Costa Rica" },
  { countryCode: "HR", countryName: "Croatie" },
  { countryCode: "CU", countryName: "Cuba" },
  { countryCode: "CW", countryName: "Curaçao" },
  { countryCode: "CY", countryName: "Chypre" },
  { countryCode: "CZ", countryName: "République tchèque" },
  { countryCode: "DK", countryName: "Danemark" },
  { countryCode: "DJ", countryName: "Djibouti" },
  { countryCode: "DM", countryName: "Dominique" },
  { countryCode: "DO", countryName: "République dominicaine" },
  { countryCode: "EC", countryName: "Équateur" },
  { countryCode: "EG", countryName: "Égypte" },
  { countryCode: "SV", countryName: "Salvador" },
  { countryCode: "GQ", countryName: "Guinée-Équatoriale" },
  { countryCode: "ER", countryName: "Érythrée" },
  { countryCode: "EE", countryName: "Estonie" },
  { countryCode: "ET", countryName: "Éthiopie" },
  { countryCode: "FK", countryName: "Îles Malouines" },
  { countryCode: "FO", countryName: "Îles Féroé" },
  { countryCode: "FJ", countryName: "Fidji" },
  { countryCode: "FI", countryName: "Finlande" },
  { countryCode: "FR", countryName: "France" },
  { countryCode: "GF", countryName: "Guayane" },
  { countryCode: "PF", countryName: "Polynésie française" },
  {
    countryCode: "TF",
    countryName: "Terres australes et antarctiques françaises"
  },
  { countryCode: "GA", countryName: "Gabon" },
  { countryCode: "GM", countryName: "Gambie" },
  { countryCode: "GE", countryName: "Géorgie" },
  { countryCode: "DE", countryName: "Allemagne" },
  { countryCode: "GH", countryName: "Ghana" },
  { countryCode: "GI", countryName: "Gibraltar" },
  { countryCode: "GR", countryName: "Grèce" },
  { countryCode: "GL", countryName: "Groenland" },
  { countryCode: "GD", countryName: "Grenade" },
  { countryCode: "GP", countryName: "Guadeloupe" },
  { countryCode: "GU", countryName: "Guam" },
  { countryCode: "GT", countryName: "Guatemala" },
  { countryCode: "GG", countryName: "Guernesey" },
  { countryCode: "GN", countryName: "Guinée" },
  { countryCode: "GW", countryName: "Guinée-Bissau" },
  { countryCode: "GY", countryName: "Guyane" },
  { countryCode: "HT", countryName: "Haïti" },
  { countryCode: "HM", countryName: "Îles Heard-et-MacDonald" },
  { countryCode: "VA", countryName: "voir Saint" },
  { countryCode: "HN", countryName: "Honduras" },
  { countryCode: "HK", countryName: "Hong Kong" },
  { countryCode: "HU", countryName: "Hongrie" },
  { countryCode: "IS", countryName: "Islande" },
  { countryCode: "IN", countryName: "Inde" },
  { countryCode: "ID", countryName: "Indonésie" },
  { countryCode: "CI", countryName: "Côte d'Ivoire" },
  { countryCode: "IR", countryName: "Iran" },
  { countryCode: "IQ", countryName: "Irak" },
  { countryCode: "IE", countryName: "Irlande" },
  { countryCode: "IM", countryName: "Île de Man" },
  { countryCode: "IL", countryName: "Israël" },
  { countryCode: "IT", countryName: "Italie" },
  { countryCode: "JM", countryName: "Jamaïque" },
  { countryCode: "JP", countryName: "Japon" },
  { countryCode: "JE", countryName: "Jersey" },
  { countryCode: "JO", countryName: "Jordanie" },
  { countryCode: "KZ", countryName: "Kazakhstan" },
  { countryCode: "KE", countryName: "Kenya" },
  { countryCode: "KI", countryName: "Kiribati" },
  { countryCode: "KW", countryName: "Koweït" },
  { countryCode: "KG", countryName: "Kirghizistan" },
  { countryCode: "LA", countryName: "Laos" },
  { countryCode: "LV", countryName: "Lettonie" },
  { countryCode: "LB", countryName: "Liban" },
  { countryCode: "LS", countryName: "Lesotho" },
  { countryCode: "LR", countryName: "Liberia" },
  { countryCode: "LY", countryName: "Libye" },
  { countryCode: "LI", countryName: "Liechtenstein" },
  { countryCode: "LT", countryName: "Lituanie" },
  { countryCode: "LU", countryName: "Luxembourg" },
  { countryCode: "MO", countryName: "Macao" },
  { countryCode: "MK", countryName: "Macédoine" },
  { countryCode: "MG", countryName: "Madagascar" },
  { countryCode: "MW", countryName: "Malawi" },
  { countryCode: "MY", countryName: "Malaisie" },
  { countryCode: "MV", countryName: "Maldives" },
  { countryCode: "ML", countryName: "Mali" },
  { countryCode: "MT", countryName: "Malte" },
  { countryCode: "MH", countryName: "Îles Marshall" },
  { countryCode: "MQ", countryName: "Martinique" },
  { countryCode: "MR", countryName: "Mauritanie" },
  { countryCode: "MU", countryName: "Île Maurice" },
  { countryCode: "YT", countryName: "Mayotte" },
  { countryCode: "MX", countryName: "Mexique" },
  { countryCode: "FM", countryName: "Micronésie" },
  { countryCode: "MD", countryName: "Moldavie" },
  { countryCode: "MC", countryName: "Monaco" },
  { countryCode: "MN", countryName: "Mongolie" },
  { countryCode: "ME", countryName: "Monténégro" },
  { countryCode: "MS", countryName: "Montserrat" },
  { countryCode: "MA", countryName: "Maroc" },
  { countryCode: "MZ", countryName: "Mozambique" },
  { countryCode: "MM", countryName: "Myanmar" },
  { countryCode: "NA", countryName: "Namibie" },
  { countryCode: "NR", countryName: "Nauru" },
  { countryCode: "NP", countryName: "Népal" },
  { countryCode: "NL", countryName: "Pays-Bas" },
  { countryCode: "NC", countryName: "Nouvelle-Calédonie" },
  { countryCode: "NZ", countryName: "Nouvelle-Zélande" },
  { countryCode: "NI", countryName: "Nicaragua" },
  { countryCode: "NE", countryName: "Niger" },
  { countryCode: "NG", countryName: "Nigéria" },
  { countryCode: "NU", countryName: "Niue" },
  { countryCode: "NF", countryName: "Île de Norfolk" },
  { countryCode: "KP", countryName: "Corée du Nord" },
  { countryCode: "MP", countryName: "Îles Mariannes du Nord" },
  { countryCode: "NO", countryName: "Norvège" },
  { countryCode: "OM", countryName: "Oman" },
  { countryCode: "PK", countryName: "Pakistan" },
  { countryCode: "PW", countryName: "Palaos" },
  { countryCode: "PS", countryName: "Palestine" },
  { countryCode: "PA", countryName: "Panama" },
  { countryCode: "PG", countryName: "Papouasie-Nouvelle-Guinée" },
  { countryCode: "PY", countryName: "Paraguay" },
  { countryCode: "PE", countryName: "Pérou" },
  { countryCode: "PH", countryName: "Philippines" },
  { countryCode: "PN", countryName: "Îles Pitcairn" },
  { countryCode: "PL", countryName: "Pologne" },
  { countryCode: "PT", countryName: "Portugal" },
  { countryCode: "PR", countryName: "Porto Rico" },
  { countryCode: "QA", countryName: "Qatar" },
  { countryCode: "RE", countryName: "Réunion" },
  { countryCode: "RO", countryName: "Roumanie" },
  { countryCode: "RU", countryName: "Russie" },
  { countryCode: "RW", countryName: "Rwanda" },
  { countryCode: "BL", countryName: "Saint-Barthélemy" },
  { countryCode: "SH", countryName: "Sainte-Hélène" },
  { countryCode: "KN", countryName: "Saint-Christophe-et-Niévès" },
  { countryCode: "LC", countryName: "Saint-Lucie" },
  { countryCode: "MF", countryName: "Saint-Martin" },
  { countryCode: "PM", countryName: "Saint-Pierre-et-Miquelon" },
  { countryCode: "VC", countryName: "Saint-Vincent-et-les-Grenadines" },
  { countryCode: "WS", countryName: "Samoa" },
  { countryCode: "SM", countryName: "Saint-Marin" },
  { countryCode: "ST", countryName: "Sao Tomé-et-Principe" },
  { countryCode: "SA", countryName: "Arabie Saoudite" },
  { countryCode: "SN", countryName: "Sénégal" },
  { countryCode: "RS", countryName: "Serbie" },
  { countryCode: "SC", countryName: "Seychelles" },
  { countryCode: "SL", countryName: "Sierra Leone" },
  { countryCode: "SG", countryName: "Singapour" },
  { countryCode: "SX", countryName: "Saint Martin (partie néerlandaise)" },
  { countryCode: "SK", countryName: "Slovaquie" },
  { countryCode: "SI", countryName: "Slovénie" },
  { countryCode: "SB", countryName: "Îles Salomon" },
  { countryCode: "SO", countryName: "Somalie" },
  { countryCode: "ZA", countryName: "Afrique du Sud" },
  {
    countryCode: "GS",
    countryName: "Géorgie du Sud-et-les Îles Sandwich du Sud"
  },
  { countryCode: "KR", countryName: "Corée du Sud" },
  { countryCode: "SS", countryName: "Soudan du Sud" },
  { countryCode: "ES", countryName: "Espagne" },
  { countryCode: "LK", countryName: "Sri Lanka" },
  { countryCode: "SD", countryName: "Soudan" },
  { countryCode: "SR", countryName: "Surinam" },
  { countryCode: "SJ", countryName: "Svalbard et Jan Mayen" },
  { countryCode: "SZ", countryName: "Swaziland" },
  { countryCode: "SE", countryName: "Suède" },
  { countryCode: "CH", countryName: "Suisse" },
  { countryCode: "SY", countryName: "Syrie" },
  { countryCode: "TW", countryName: "Taïwan" },
  { countryCode: "TJ", countryName: "Tadjikistan" },
  { countryCode: "TZ", countryName: "Tanzanie" },
  { countryCode: "TH", countryName: "Thaïlande" },
  { countryCode: "TL", countryName: "Timor oriental" },
  { countryCode: "TG", countryName: "Togo" },
  { countryCode: "TK", countryName: "Tokelau" },
  { countryCode: "TO", countryName: "Tonga" },
  { countryCode: "TT", countryName: "Trinité et Tobago" },
  { countryCode: "TN", countryName: "Tunisie" },
  { countryCode: "TR", countryName: "Turquie" },
  { countryCode: "TM", countryName: "Turkménistan" },
  { countryCode: "TC", countryName: "Îles Turques-et-Caïques" },
  { countryCode: "TV", countryName: "Tuvalu" },
  { countryCode: "UG", countryName: "Uganda" },
  { countryCode: "UA", countryName: "Ukraine" },
  { countryCode: "AE", countryName: "Émirats arabes unis" },
  { countryCode: "GB", countryName: "Royaume-Uni" },
  { countryCode: "US", countryName: "États-Unis" },
  { countryCode: "UY", countryName: "Uruguay" },
  { countryCode: "UZ", countryName: "Ouzbékistan" },
  { countryCode: "VU", countryName: "Vanuatu" },
  { countryCode: "VE", countryName: "Venezuela" },
  { countryCode: "VN", countryName: "Viêt Nam" },
  { countryCode: "WF", countryName: "Wallis-et-Futuna" },
  { countryCode: "EH", countryName: "Sahara Occidental" },
  { countryCode: "YE", countryName: "Yémen" },
  { countryCode: "ZM", countryName: "Zambie" },
  { countryCode: "ZW", countryName: "Zimbabwe" }
];
