<template>
  <ErrorMessage :error="error">
    <ValidationProvider class="block" style="margin-bottom: 0" immediate>
      <FieldWithMessage
        :label="$t('INTERESTS')"
        :tooltip="{
          label: $t('INTERESTS_ARE_DEFINED_AND_GENERATED_DIRECTLY_BY_FACEBOOK'),
          multilined: true
        }"
        position="top"
      >
        <b-taginput
          v-model="target.interests"
          :data="filteredInterests"
          :placeholder="$t('SEARCH')"
          :loading="$apollo.loading"
          :disabled="disabled"
          field="name"
          autocomplete
          open-on-focus
          attached
          @typing="input => (interestsSearch = input)"
          @add="interestsSearch = ''"
        >
          <template #default="{ option: { name } }">
            {{ name }}
          </template>
        </b-taginput>
      </FieldWithMessage>
    </ValidationProvider>
  </ErrorMessage>
</template>

<script>
import FieldWithMessage from "@/components/FieldWithMessage.vue";
import { escapeRegExp } from "lodash";
import { ValidationProvider } from "vee-validate";
import ErrorMessage from "@/components/ErrorMessage.vue";
import interestsQuery from "./queries/interests.gql";

export default {
  components: { FieldWithMessage, ValidationProvider, ErrorMessage },
  props: { disabled: { type: Boolean, default: false } },
  data: () => ({ interestsSearch: "", error: "" }),
  inject: ["audienceTab"],
  apollo: {
    availableInterests: {
      query: interestsQuery,
      update: ({ listInterests }) => listInterests,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: {
    target: ({ audienceTab: { target } }) => target,
    filteredInterests: ({
      availableInterests = [],
      interestsSearch,
      target: { interests }
    }) =>
      availableInterests.filter(
        ({ name }) =>
          RegExp(escapeRegExp(interestsSearch), "i").test(name) &&
          !interests.some(interet => interet.name === name)
      )
  }
};
</script>

<style scoped></style>
