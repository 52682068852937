export const getNormalizedCsvFile = (filename, emailHeader = "", rows = []) =>
  new File(
    [
      rows.reduce(
        (acc, row) => `${acc}${row[emailHeader]}\n`,
        `${emailHeader}\n`
      )
    ],
    filename,
    { type: "text/csv;charset=utf-8" }
  );
