<template>
  <ValidationProvider
    class="block pb-3"
    style="margin-bottom: 0"
    ref="ageRangeValidation"
  >
    <b-field :label="$t('AGE')">
      <b-slider
        :value="target.ageRange"
        @input="value => (target.ageRange = value)"
        @change="
          // Workaround for vee-validate which considere ageRange slider as dirty
          // on initial rendering when using v-model (probably because v-model is an array).
          $refs.ageRangeValidation.setFlags({
            pristine: false,
            dirty: true
          })
        "
        :min="18"
        :max="65"
        :tooltip="false"
        size="is-medium"
        class="px-3"
        :disabled="disabled"
      >
        <b-slider-tick :value="18">
          <div class="text-base pt-1.5">18</div>
        </b-slider-tick>
        <b-slider-tick v-show="minAge > 18" :value="minAge">
          <div class="text-base pt-1.5">{{ minAge }}</div>
        </b-slider-tick>
        <b-slider-tick v-show="maxAge < 65" :value="maxAge">
          <div class="text-base pt-1.5">{{ maxAge }}</div>
        </b-slider-tick>
        <b-slider-tick :value="65">
          <div class="text-base pt-1.5">65+</div>
        </b-slider-tick>
      </b-slider>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: { ValidationProvider },
  props: { disabled: { type: Boolean, default: false } },
  inject: ["audienceTab"],
  computed: {
    target: ({ audienceTab: { target } }) => target,
    minAge: ({ target: { ageRange } }) => ageRange[0],
    maxAge: ({ target: { ageRange } }) => ageRange[1]
  }
};
</script>

<style scoped></style>
