<template>
  <div class="flex items-end">
    <b-field
      style="margin-bottom: 0"
      class="max-w-full"
      :class="position === 'right' ? 'w-1/2 mr-4' : 'w-full'"
    >
      <template #label>
        {{ label }}
        <b-tooltip v-if="tooltip" v-bind="tooltipProps">
          <template #content>
            <span class="text-xs">{{ tooltip.label }}</span>
          </template>
          <fa :icon="tooltipProps.icon" />
        </b-tooltip>
        <b-message
          v-if="($scopedSlots.message || message) && position === 'top'"
          :type="type"
          class="field-message font-normal source-sans-pro"
        >
          <div class="text-left">
            <slot name="message"><span v-html="message"></span></slot>
          </div>
        </b-message>
      </template>
      <slot></slot>
    </b-field>
    <div v-if="position === 'right'" class="w-1/2 ml-4">
      <b-message
        v-if="($scopedSlots.message || message) && position === 'right'"
        :type="type"
        class="field-message"
      >
        <div class="text-left">
          <slot name="message"><span v-html="message"></span></slot>
        </div>
      </b-message>
    </div>
  </div>
</template>

<script>
export const defaultTooltipProps = {
  type: "is-dark",
  class: "ml-1",
  icon: ["fas", "question-circle"]
};

export default {
  props: {
    label: { type: String, default: "" },
    type: { type: String, default: "is-info" },
    message: { type: String, default: "" },
    position: {
      type: String,
      default: "right",
      validator: position => ["top", "right"].includes(position)
    },
    tooltip: { type: Object, default: null }
  },
  computed: {
    tooltipProps: ({ tooltip: { label: _label, ...rest } }) => ({
      ...defaultTooltipProps,
      ...rest
    })
  }
};
</script>

<style>
.field-message .message-body {
  @apply p-2.5 !important;
}
</style>
