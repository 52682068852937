<template>
  <SummaryContainer
    :name="$t('DIFFUSION')"
    :status="diffusionStatus"
    :loading="$apollo.loading"
    :error="error"
  >
    <div class="flex items-center">
      <StartAction
        v-show="!['EXECUTING', 'SCHEDULED', 'DONE'].includes(action.status)"
        :disabled="diffusionStatus !== 'CONFIGURED'"
        v-bind="action.configuration"
      />
      <b-button
        v-show="['EXECUTING', 'SCHEDULED'].includes(action.status)"
        type="is-danger"
        @click="stopAction"
        :loading="loading"
      >
        {{ $t("STOP_ACTION") }}
      </b-button>
      <b-button
        v-show="action.status === 'DONE'"
        type="is-primary"
        tag="router-link"
        :to="{
          name: 'facebookAction',
          params: { ...$route.params, tab: 'results' }
        }"
        >{{ $t("RESULTS") }}</b-button
      >
      <div class="flex-grow">
        <b-message
          v-show="action.status === 'SCHEDULED'"
          type="is-info"
          class="compact-message ml-8"
        >
          <div class="text-left">
            {{
              $t(
                "THIS_ACTION_WILL_BE_BROADCAST_FROM_STARTDATE_TO_ENDDATE",
                budgetDates
              )
            }}
          </div>
        </b-message>
        <div v-show="action.status === 'EXECUTING'" class="pl-8">
          <div class="pb-4 text-center">{{ $t("BROADCASTING") }}</div>
          <b-progress type="is-primary" size="is-small" />
        </div>
        <b-message
          v-show="action.status === 'DONE'"
          type="is-success"
          class="compact-message ml-8"
        >
          <div class="text-left">
            {{ $t("THIS_ACTION_ENDED_ON_ENDDATE", budgetDates) }}.
            {{ $t("YOU_CAN_NOW_CHECK_THE_RESULTS") }}
          </div>
        </b-message>
        <div
          v-show="!['SCHEDULED', 'EXECUTING', 'DONE'].includes(action.status)"
        >
          <div class="text-center">
            {{
              $t(
                "TO_LAUNCH_YOUR_FACEBOOK_ACTION_THIS_STEPS_HAVE_TO_BE_CONFIGURED"
              )
            }}
          </div>
          <div class="flex justify-center p-4">
            <div
              v-for="({ icon, color }, labelKey) in stepsStatusIcons"
              :key="labelKey"
              class="px-8 py-2 whitespace-nowrap"
            >
              <fa class="mr-2" :class="color" :icon="icon" size="lg" />
              <span class="poppins">{{ $t(labelKey) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 flex" v-show="action.status === 'DRAFT'">
      <div class="flex items-center w-20 text-gray-400">
        <fa :icon="['fas', 'info-circle']" size="lg" />
      </div>
      <div class="flex-grow text-gray-500">
        {{ $t("A_FACEBOOK_ACTION_IS_PLANNED_OVER_A_PERIOD_OF_TIME") }}.
        {{
          $t(
            "ONCE_THE_ACTION_LAUNCHED_ON_ACTIVATION_IT_WILL_BE_BROADCAST_ON_THE_SELECTED_PLATFORMS_WITH_THE_ELEMENTS_THAT_YOU_HAVE_CONFIGURED"
          )
        }}.
      </div>
    </div>
  </SummaryContainer>
</template>

<script>
import SummaryContainer from "../SummaryContainer.vue";
import StartAction from "./components/StartAction/StartAction.vue";
import actionQuery from "./queries/action.gql";
import stopActionQuery from "./queries/stopAction.gql";
import { success, error } from "@/helpers/notification.js";
import { settingStatus } from "@/FacebookAction/SettingTab/SettingTab.vue";
import { audienceStatus } from "@/FacebookAction/AudienceTab/AudienceTab.vue";
import { budgetStatus } from "@/FacebookAction/BudgetTab/BudgetTab.vue";
import { visualStatus } from "@/FacebookAction/VisualTab/VisualTab.vue";
import { ACTION_STEP_STATUS_STYLE } from "@/FacebookAction/action.const.js";

const budgetDateToLocaleString = date =>
  new Date(date).toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  });

// See src/Action/Facebook/BudgetTab/BudgetTab.vue for details about why
// we need this function for now
const dateSixMonthsAgo = (date = new Date()) =>
  new Date(new Date(date).setMonth(date.getMonth() - 6));

export default {
  components: { SummaryContainer, StartAction },
  data: () => ({ action: {}, loading: false, error: "" }),
  apollo: {
    action: {
      query: actionQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { actionId };
      },
      update({ facebookAction }) {
        const { status, configuration } = facebookAction;
        const startTime =
          status === "DRAFT"
            ? dateSixMonthsAgo(new Date(configuration?.startTime ?? Date.now()))
            : configuration?.startTime;
        const endTime =
          status === "DRAFT"
            ? dateSixMonthsAgo(new Date(configuration?.endTime ?? Date.now()))
            : configuration?.endTime;
        const tenMinutesFromNow = new Date(
          new Date().setMinutes(new Date().getMinutes() + 10)
        );

        // auto refresh the status every 60s if needed
        switch (status) {
          case "SCHEDULED":
            new Date(startTime) < tenMinutesFromNow &&
              this.$apollo.queries.action.startPolling(60000);
            break;
          case "EXECUTING":
            tenMinutesFromNow > new Date(endTime) &&
              this.$apollo.queries.action.startPolling(60000);
            break;
          default:
            this.$apollo.queries.action.stopPolling();
        }

        return {
          ...facebookAction,
          configuration: { ...configuration, startTime, endTime }
        };
      },
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: {
    budgetDates: ({ action: { configuration } }) => ({
      startDate: budgetDateToLocaleString(configuration?.startTime ?? 0),
      endDate: budgetDateToLocaleString(configuration?.endTime ?? 0)
    }),
    settingStatus,
    audienceStatus,
    budgetStatus,
    visualStatus,
    stepsStatusIcons: ({
      settingStatus,
      audienceStatus,
      budgetStatus,
      visualStatus
    }) => ({
      SETTING: {
        icon: ACTION_STEP_STATUS_STYLE[settingStatus].icon,
        color: ACTION_STEP_STATUS_STYLE[settingStatus].color
      },
      AUDIENCE: {
        icon: ACTION_STEP_STATUS_STYLE[audienceStatus].icon,
        color: ACTION_STEP_STATUS_STYLE[audienceStatus].color
      },
      BUDGET: {
        icon: ACTION_STEP_STATUS_STYLE[budgetStatus].icon,
        color: ACTION_STEP_STATUS_STYLE[budgetStatus].color
      },
      VISUAL: {
        icon: ACTION_STEP_STATUS_STYLE[visualStatus].icon,
        color: ACTION_STEP_STATUS_STYLE[visualStatus].color
      }
    }),
    diffusionStatus: ({
      settingStatus,
      audienceStatus,
      budgetStatus,
      visualStatus
    }) =>
      [settingStatus, audienceStatus, budgetStatus, visualStatus].every(
        status =>
          // /!\ Here we are testing object reference equality (aka same object instance)
          // It works because status mapping in ACTION_STEP_STATUS_STYLE is based on object getter
          ACTION_STEP_STATUS_STYLE[status] ===
          ACTION_STEP_STATUS_STYLE.CONFIGURED
      )
        ? "CONFIGURED"
        : "NOT_CONFIGURED",
    amount: ({ action: { configuration } }) =>
      +(configuration?.lifetimeBudget ?? 0) / 100
  },
  methods: {
    stopAction() {
      this.loading = true;
      const { actionId } = this.$route.params;

      this.$apollo
        .mutate({
          mutation: stopActionQuery,
          variables: { actionId }
        })
        .then(() => success(this.$t("SUCCESSFULLY_SAVED")))
        .catch(({ graphQLErrors, networkError }) =>
          error(JSON.stringify(networkError || graphQLErrors[0].message))
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
