<template>
  <div>
    <h1 v-if="loading" style="text-align: center">
      <fa :icon="['fas', 'spinner']" :size="size" spin />
    </h1>
    <slot v-else />
  </div>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
    size: { type: String }
  }
};
</script>
