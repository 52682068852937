<template>
  <div class="modal" :class="{ 'is-active': isOpen }">
    <div class="modal-background"></div>
    <div class="modal-card" :style="modalCardStyle">
      <header class="modal-card-head">
        <div class="modal-card-title">{{ title }}</div>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <section class="modal-card-body"><slot></slot></section>
      <footer class="modal-card-foot"><slot name="foot"></slot></footer>
    </div>
  </div>
</template>

<script>
export default { props: ["isOpen", "title", "modalCardStyle"] };
</script>

<style scoped>
.modal-card-body {
  height: 100%;
}
.modal-card-foot {
  justify-content: flex-end;
}
</style>
