<template>
  <div class="space-y-10">
    <DiffusionSummary />
    <SettingSummary />
    <AudienceSummary />
    <BudgetSummary />
    <VisualSummary />
  </div>
</template>

<script>
import DiffusionSummary from "./components/DiffusionSummary/DiffusionSummary.vue";
import SettingSummary from "./components/SettingSummary.vue";
import AudienceSummary from "./components/AudienceSummary/AudienceSummary.vue";
import BudgetSummary from "./components/BudgetSummary/BudgetSummary.vue";
import VisualSummary from "./components/VisualSummary/VisualSummary.vue";

export default {
  components: {
    DiffusionSummary,
    SettingSummary,
    AudienceSummary,
    BudgetSummary,
    VisualSummary
  }
};
</script>
