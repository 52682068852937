export const adAccountStatusList = {
  "1": "ACTIVE",
  "2": "DISABLED",
  "3": "UNSETTLED",
  "7": "PENDING_RISK_REVIEW",
  "8": "PENDING_SETTLEMENT",
  "9": "IN_GRACE_PERIOD",
  "100": "PENDING_CLOSURE",
  "101": "CLOSED",
  "201": "ANY_ACTIVE",
  "202": "ANY_CLOSED"
};

export const accountDisableReasonsList = {
  "0": "NONE",
  "1": "ADS_INTEGRITY_POLICY",
  "2": "ADS_IP_REVIEW",
  "3": "RISK_PAYMENT",
  "4": "GRAY_ACCOUNT_SHUT_DOWN",
  "5": "ADS_AFC_REVIEW",
  "6": "BUSINESS_INTEGRITY_RAR",
  "7": "PERMANENT_CLOSE",
  "8": "UNUSED_RESELLER_ACCOUNT",
  "9": "UNUSED_ACCOUNT"
};
