<template>
  <div class="flex">
    <div
      class="flex flex-shrink-0 mr-2"
      :class="$scopedSlots.message ? 'w-1/2' : 'flex-grow'"
    >
      <div class="w-18">
        <fa :icon="['fab', 'instagram']" size="3x" class="text-orange-500" />
      </div>
      <div class="ml-5 pl-5 border-l">
        <div class="poppins pb-3">{{ $t("INSTAGRAM") }}</div>
        <div
          class="field"
          v-for="(instaSwitch, key) in instragramSwitches"
          :key="key"
        >
          <b-switch v-if="!readonly" v-model="vm[key]" :disabled="disabled">
            {{ $t(instaSwitch) }}
          </b-switch>
          <div v-if="readonly" class="flex">
            <fa
              :icon="vm[key] ? ['fas', 'check'] : ['fas', 'times']"
              class="mr-2"
              :class="vm[key] ? 'text-green-500' : 'text-gray-400'"
              fixed-width
            />
            <div>{{ $t(instaSwitch) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$scopedSlots.message" class="w-1/2 flex-shrink-0 ml-2">
      <b-message :type="type" class="field-message">
        <div class="text-left">
          <slot name="message"></slot>
        </div>
      </b-message>
    </div>
  </div>
</template>

<script>
const instragramSwitches = { instagram: "NEWS_FEEDS_AND_STORIES" };

export default {
  props: {
    value: {
      type: Object,
      default: () =>
        // default value for each switch is "false"
        Object.keys(instragramSwitches).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {}
        )
    },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: "is-info" },
    readonly: { type: Boolean, default: false }
  },
  computed: {
    vm: vm => vm, // allow access to "this" (as "vm") in template
    // generate a computed getter/setter for each switch for v-model support
    instragramSwitches: () => instragramSwitches,
    ...Object.keys(instragramSwitches).reduce(
      (acc, switchKey) => ({
        ...acc,
        [switchKey]: {
          get: ({ value }) => value[switchKey],
          set(value) {
            this.$emit("input", { ...this.value, [switchKey]: value });
          }
        }
      }),
      {}
    )
  }
};
</script>

<style scoped></style>
