<template>
  <div class="relative">
    <slot></slot>
    <div class="overlay" v-show="visible">
      <div class="bg-white rounded shadow p-4">
        <slot name="overlay"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default { props: { visible: { type: Boolean, default: false } } };
</script>

<style scoped>
.overlay {
  @apply absolute inset-0 flex items-center justify-center z-20;
  background-color: rgba(45, 55, 72, 0.5);
}
</style>
