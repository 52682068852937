<template>
  <ValidationProvider class="block" style="margin-bottom: 0" immediate>
    <label class="label">{{ $t("GENDER") }}</label>
    <b-field>
      <b-radio-button
        v-for="{ value, labelKey } in genders"
        :key="labelKey"
        v-model="target.gender"
        :native-value="value"
        :disabled="disabled"
      >
        {{ $t(labelKey) }}
      </b-radio-button>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export const genders = [
  { value: "ALL", labelKey: "ALL" },
  { value: "MALE", labelKey: "MEN" },
  { value: "FEMALE", labelKey: "WOMEN" }
];

export default {
  components: { ValidationProvider },
  props: { disabled: { type: Boolean, default: false } },
  inject: ["audienceTab"],
  computed: {
    target: ({ audienceTab: { target } }) => target,
    genders: () => genders
  }
};
</script>

<style scoped></style>
