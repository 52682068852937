<template>
  <b-collapse class="card" :open="false">
    <template #trigger="{ open }">
      <div class="card-header" role="button">
        <p class="card-header-title">
          {{ $t("CSV_FILE") }}
          <b-tag type="is-info" class="source-sans-pro font-normal ml-2">
            {{ source.csvFile ? source.csvFile.length : 0 }}
          </b-tag>
        </p>

        <a class="card-header-icon">
          <fa :icon="open ? ['fas', 'caret-down'] : ['fas', 'caret-up']" />
        </a>
      </div>
    </template>

    <div class="card-content">
      <div class="content">
        <FieldWithMessage position="top">
          <CsvFileUploader
            v-model="source.csvFile"
            @input="Object.assign(source, { pristine: false, bookmarks: [] })"
            :disabled="disabled || !!source.bookmarks[0]"
          />
          <template #message>
            {{ $t("THE_FIRST_LINE_IS_THE_COLUMN_NAME") }}
            {{ $t("CHARSET_MUST_BE_UTF8") }}
          </template>
        </FieldWithMessage>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import FieldWithMessage from "@/components/FieldWithMessage.vue";
import CsvFileUploader from "@/components/CsvFileUploader.vue";

export default {
  components: { FieldWithMessage, CsvFileUploader },
  props: { disabled: { type: Boolean, default: false } },
  inject: ["audienceTab"],
  computed: { source: ({ audienceTab: { source } }) => source }
};
</script>

<style scoped></style>
