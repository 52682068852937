<template>
  <Loader :loading="$apollo.loading">
    <ErrorMessage :error="error">
      <b-tabs :value="tabIndex" class="facebook-action-tabs mb-0">
        <b-tab-item
          v-for="({ labelKey, path, component }, index) in tabs"
          :key="path"
          :label="$t(labelKey)"
        >
          <template #header>
            <div
              @click.stop="changeTab(index)"
              class="py-2 px-4 text-sm font-semibold hover:text-activation transition-colors duration-500"
              :class="
                index === tabIndex
                  ? 'border-b-2 border-activation'
                  : 'text-gray-600'
              "
            >
              {{ $t(labelKey) }}
            </div>
          </template>
          <ValidationObserver tag="div" v-slot="formValidation">
            <component
              :is="component"
              v-if="index === tabIndex"
              :form-validation="formValidation"
              ref="currentTab"
            ></component>
          </ValidationObserver>
        </b-tab-item>
      </b-tabs>
      <Modal
        :is-open="openSaveModal"
        :title="$t('UNSAVED_CHANGED')"
        @close="loading || (openSaveModal = false)"
      >
        {{ $t("SOME_CHANGES_HAVENT_BEEN_SAVED_YET") }}<br />
        {{ $t("WHAT_DO_YOU_WANT_TO_DO") }}
        <template slot="foot">
          <b-button @click="continueEditing" :disabled="loading">
            {{ $t("CONTINUE_EDITING") }}
          </b-button>
          <b-button @click="goToTab(nextTabIndex)" :disabled="loading">
            {{ $t("LEAVE_WITHOUT_SAVING") }}
          </b-button>
          <b-button
            @click="saveAndLeave"
            :loading="loading"
            :disabled="
              $refs.currentTab &&
                $refs.currentTab[0] &&
                !$refs.currentTab[0].isValid
            "
          >
            {{ $t("SAVE_AND_LEAVE") }}
          </b-button>
        </template>
      </Modal>
    </ErrorMessage>
  </Loader>
</template>

<script>
import Loader from "@/components/Loader.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import { ValidationObserver } from "vee-validate";
import Modal from "@/components/Modal.vue";
import facebookActionQuery from "./queries/facebookAction.gql";
import facebookTabs from "./tabs.const.js";
import { error } from "@/helpers/notification.js";
import { settingStatus } from "./SettingTab/SettingTab.vue";

export default {
  components: { Loader, ErrorMessage, ValidationObserver, Modal },
  props: { actionId: { type: String, required: true } },
  data: () => ({
    action: {},
    loading: false,
    error: "",
    openSaveModal: false,
    nextTabIndex: -1,
    next: () => true
  }),
  apollo: {
    action: {
      query: facebookActionQuery,
      variables() {
        return { id: this.actionId };
      },
      update({ facebookAction }) {
        if (settingStatus({ action: facebookAction }) === "NOT_CONFIGURED")
          this.$router
            .push({
              name: "facebookAction",
              params: { ...this.$route.params, tab: "initialization" }
            })
            .catch(() => {});

        return { ...facebookAction, channel: "FACEBOOK" };
      },
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors);
      }
    }
  },
  computed: {
    tabs: () => facebookTabs,
    tabIndex: ({ $route, tabs }) => {
      const { tab = "general" } = $route.params;
      const tabIndex = tabs.findIndex(({ path }) => path === tab);

      return tabIndex < 0 ? 0 : tabIndex;
    }
  },
  methods: {
    async goToTab(tabIndex) {
      if (!this.next()) return; // return if going outside FB action

      const { campaignId, actionId } = this.$route.params;
      const { path } = this.tabs[tabIndex];

      return this.$router
        .push({
          name: "facebookAction",
          params: { campaignId, actionId, tab: path }
        })
        .then(() =>
          Object.assign(this, { openSaveModal: false, next: () => true })
        )
        .catch(() => {});
    },
    changeTab(tabIndex) {
      if (!this.$refs.currentTab[0].isDirty) return this.goToTab(tabIndex);
      Object.assign(this, { nextTabIndex: tabIndex, openSaveModal: true });
    },
    continueEditing() {
      Object.assign(this, { openSaveModal: false, next: () => true });
    },
    saveAndLeave() {
      this.loading = true;
      this.$refs.currentTab[0]
        .save()
        .catch(({ graphQLErrors, networkError }) =>
          error(JSON.stringify(networkError || graphQLErrors[0].message))
        )
        .then(() => this.goToTab(this.nextTabIndex))
        .finally(() => (this.loading = false));
    }
  },
  beforeRouteLeave(_to, _from, next) {
    if (!this.$refs.currentTab[0].isDirty) return next();

    Object.assign(this, { openSaveModal: true, next });
  }
};
</script>

<style>
.facebook-action-tabs > .tab-content {
  margin-top: 2rem;
  padding: 0 !important;
}

.facebook-action-tabs > .tabs a {
  padding: 0 !important;
  border-bottom: 0;
  margin-right: 16px;
}
</style>
