import i18n from "@/plugins/i18n.js";
import { NotificationProgrammatic as Notification } from "buefy";

export const success = message =>
  Notification.open({
    message,
    type: "is-success",
    position: "is-top-right",
    duration: 5000
  });

export const warning = message =>
  Notification.open({
    message,
    type: "is-warning",
    position: "is-top-right",
    indefinite: true
  });

export const error = message =>
  Notification.open({
    message: `<b>${i18n.t("OUPS_SOMETHING_GOES_WRONG")}</b>
            <br />
            ${i18n.t("TRY_AGAIN_IN_A_FEW_MINUTES_OR_CONTACT_OUR_SUPPORT")}
            <br />
            <br />
            <hr />
            ${i18n.t("SEE_TECHNICAL_DETAILS")}:
            <br />
            <pre
              class="has-background-light is-size-7 break-all mt-2 rounded text-left"
              style="white-space: pre-wrap"
            >
              ${message}
            </pre>`,
    type: "is-danger",
    position: "is-top-right",
    indefinite: true
  });
