<template>
  <SummaryContainer
    :name="$t('BUDGET')"
    :status="budgetStatus"
    tab="budget"
    :loading="$apollo.loading"
    :error="error"
    :setting-status="settingStatus"
  >
    <div
      v-show="budgetStatus === 'NOT_CONFIGURED'"
      class="text-center text-gray-500"
    >
      {{ $t("THIS_STEP_ISNT_CONFIGURED_YET") }}
    </div>
    <div v-show="budgetStatus === 'CONFIGURED'">
      <div class="flex py-2">
        <div class="w-1/5 label">{{ $t("START") }}</div>
        <div>
          {{
            action.configuration &&
              new Date(action.configuration.startTime).toLocaleString()
          }}
        </div>
      </div>
      <div class="flex py-2">
        <div class="w-1/5 label">{{ $t("END") }}</div>
        <div>
          {{
            action.configuration &&
              new Date(action.configuration.endTime).toLocaleString()
          }}
        </div>
      </div>
      <div class="flex py-2">
        <div class="w-1/5 label">{{ $t("AMOUNT") }}</div>
        <div>
          {{
            action.configuration && action.configuration.lifetimeBudget / 100
          }}
          €
        </div>
      </div>
    </div>
  </SummaryContainer>
</template>

<script>
import SummaryContainer from "../SummaryContainer.vue";
import actionQuery from "./queries/action.gql";
import { settingStatus } from "@/FacebookAction/SettingTab/SettingTab.vue";
import { budgetStatus } from "@/FacebookAction/BudgetTab/BudgetTab.vue";

// See src/Action/Facebook/BudgetTab/BudgetTab.vue for details about why
// we need this function for now
const dateSixMonthsAgo = (date = new Date()) =>
  new Date(new Date(date).setMonth(date.getMonth() - 6));

export default {
  components: { SummaryContainer },
  data: () => ({ action: {}, error: "" }),
  apollo: {
    action: {
      query: actionQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { actionId };
      },
      update: ({ facebookAction: { status, configuration, ...rest } }) => ({
        ...rest,
        status,
        configuration: {
          ...configuration,
          startTime:
            status === "DRAFT"
              ? dateSixMonthsAgo(
                  new Date(configuration?.startTime ?? Date.now())
                )
              : configuration?.startTime,
          endTime:
            status === "DRAFT"
              ? dateSixMonthsAgo(new Date(configuration?.endTime ?? Date.now()))
              : configuration?.endTime
        }
      }),
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: { budgetStatus, settingStatus }
};
</script>
