<template>
  <b-collapse class="card overflow-visible" :open="false">
    <template #trigger="{ open }">
      <div class="card-header" role="button">
        <p class="card-header-title">
          {{ $t("IGNITION_BOOKMARKS") }}
          <b-tag type="is-info" class="source-sans-pro font-normal ml-2">
            <fa
              v-show="isTotalContactsLoading"
              :icon="['fas', 'spinner']"
              spin
            />
            <span v-show="!isTotalContactsLoading">
              {{ totalAudience || (source.bookmarks[0] ? audienceLength : 0) }}
            </span>
          </b-tag>
        </p>

        <a class="card-header-icon">
          <fa :icon="open ? ['fas', 'caret-down'] : ['fas', 'caret-up']" />
        </a>
      </div>
    </template>

    <div class="card-content">
      <div class="content">
        <b-field>
          <b-taginput
            v-model="source.bookmarks"
            :data="filteredBookmarks"
            field="name"
            :placeholder="$t('SEARCH')"
            autocomplete
            open-on-focus
            attached
            @input="value => getAudienceInfo(value.map(({ id }) => id))"
            @typing="input => (search = input)"
            @add="addBookmark"
            :loading="bookmarksRequestLoading"
            :disabled="disabled || bookmarksRequestLoading || !!source.csvFile"
          >
            <template
              #default="{ option: { name, combined = false, modifiedDate, author, tags = [] } }"
            >
              <div class="text-base">
                <div class="flex items-center">
                  <div class="flex-shrink-0 w-6 text-center">
                    <b-tooltip
                      :active="combined"
                      type="is-dark"
                      label="Issu d'une combinaison de marque-pages"
                      position="is-right"
                    >
                      <span>
                        <fa
                          :icon="['fal', 'bookmark']"
                          size="lg"
                          fixed-width
                          class="text-gray-500"
                          :class="{ '-mt-2': combined }"
                        />
                        <fa
                          v-show="combined"
                          :icon="['fas', 'bookmark']"
                          size="lg"
                          fixed-width
                          class="-ml-8 -mb-2 text-blue-400"
                        />
                      </span>
                    </b-tooltip>
                  </div>
                  <div class="flex-grow text-gray-800 text-left pl-4">
                    <div>{{ name }}</div>
                    <div class="text-sm text-gray-500">
                      {{ modifiedDate | toLocaleString }}
                      <span v-show="author"> | {{ author }}</span>
                    </div>
                    <div class="flex flex-wrap -ml-1">
                      <span
                        v-for="tag in tags"
                        class="tag is-primary is-light ml-1 mt-1"
                        :key="tag"
                      >
                        <fa :icon="['fas', 'tag']" class="mr-1" />
                        {{ tag }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-taginput>
        </b-field>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { escapeRegExp } from "lodash";

export default {
  props: {
    disabled: { type: Boolean, default: false },
    audienceLength: { type: Number, default: 0 }
  },
  data: () => ({ search: "" }),
  mounted() {
    if (this.availableBookmarks.length) {
      this.getAudienceInfo([]); // Reset Qlik bookmarks selection (just in case)
    } else {
      this.fetchBookmarks(); // Fetch bookmarks if not already loaded
    }
  },
  inject: ["audienceTab"],
  computed: {
    source: ({ audienceTab: { source } }) => source,
    ...mapState({
      availableBookmarks: ({ qlik: { bookmarks } }) => bookmarks,
      bookmarksRequestLoading: ({ qlik: { bookmarksRequestLoading } }) =>
        bookmarksRequestLoading,
      totalAudience: ({ qlik: { totalAudience } }) => totalAudience,
      isTotalContactsLoading: ({ qlik: { isTotalContactsLoading } }) =>
        isTotalContactsLoading
    }),
    filteredBookmarks: ({ availableBookmarks, search, source }) => {
      const searchTerms = escapeRegExp(search).split(" ");

      // spread avoid sort side effect
      return (
        [...availableBookmarks]
          .map(
            // newly created bookmarks doesn't have a modifiedDate (Qlik bug?)
            // as a workaround, we set the modified date as description
            ({ description, modifiedDate, ...rest }) => ({
              ...rest,
              ...(description[0] === "{" ? JSON.parse(description) : {}),
              ...(modifiedDate && { modifiedDate })
            })
          )
          // filter bookmarks by search terms (could be in title, author or tags)
          .filter(({ name, author = "", tags = [] }) => {
            const searcheableString = `${name} ${author} ${tags.join(" ")}`;
            const searchMatch = searchTerms.every(term =>
              RegExp(term, "i").test(searcheableString)
            );
            const selected = source.bookmarks.some(
              ({ name: tagName }) => tagName === name
            );

            return searchMatch && !selected;
          })
          .sort(
            ({ modifiedDate: a }, { modifiedDate: b }) =>
              new Date(b) - new Date(a)
          )
      );
    }
  },
  methods: {
    ...mapActions(["fetchBookmarks", "getAudienceInfo"]),
    addBookmark() {
      this.search = "";
      this.source.pristine = false;
      this.source.csvFile = null;
    }
  },
  filters: {
    toLocaleString: date =>
      new Date(date).toLocaleString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      })
  }
};
</script>
