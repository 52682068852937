export const ACTION_GOAL_ICON = {
  INVITATION: "user-plus",
  PROMOTION: "badge-dollar",
  LAUNCH: "rocket",
  NOTORIETY: "users",
  RELAUNCH: "sync",
  DISCOUNT: "badge-dollar",
  SERVICE: "calendar-times"
};

export const ACTION_STATUS_BACKGROUND = {
  DRAFT: "has-background-warning has-text-dark",
  WAITING: "has-background-info",
  SCHEDULED: "has-background-info",
  EXECUTING: "has-background-green has-text-dark",
  STOPPED: "has-background-info",
  ABORTED_BAD_REPUTATION: "has-background-danger",
  ABORTED_OVERPRESSURE: "has-background-danger",
  NOT_ENOUGH_QUOTA: "has-background-danger",
  ERROR: "has-background-danger",
  DONE: "has-background-success"
};

export const ACTION_STATUS_COLOR = {
  DRAFT: "is-warning",
  WAITING: "is-info",
  SCHEDULED: "is-info",
  EXECUTING: "is-green",
  STOPPED: "is-info",
  ABORTED_BAD_REPUTATION: "is-danger",
  ABORTED_OVERPRESSURE: "is-danger",
  NOT_ENOUGH_QUOTA: "is-danger",
  ERROR: "is-danger",
  DONE: "is-success"
};

export const ACTION_CHANNEL_ICON = {
  EMAIL: ["far", "envelope"],
  FACEBOOK: ["fab", "facebook"]
};

export const ACTION_STEP_STATUS_STYLE = {
  CONFIGURED: {
    icon: ["fas", "check-circle"],
    color: "text-green-500",
    tagType: "is-success",
    labelKey: "CONFIGURED"
  },
  get READY() {
    return this.CONFIGURED;
  },
  get ACTIVE() {
    return this.CONFIGURED;
  },
  get IN_PROCESS() {
    return this.CONFIGURED;
  },
  get PAUSED() {
    return this.CONFIGURED;
  },
  NOT_CONFIGURED: {
    icon: ["fas", "times-circle"],
    color: "text-gray-400",
    tagType: "is-default",
    labelKey: "NOT_CONFIGURED"
  },
  WAITING: {
    icon: ["fas", "clock"],
    color: "text-blue-500",
    tagType: "is-info",
    labelKey: "WAITING"
  },
  get CREATING() {
    return { ...this.WAITING, labelKey: "CREATING" };
  },
  get PENDING_REVIEW() {
    return { ...this.WAITING, labelKey: "PENDING_REVIEW" };
  },
  ERROR: {
    icon: ["fas", "exclamation-circle"],
    color: "text-red-500",
    tagType: "is-danger",
    labelKey: "ERROR"
  },
  get TOO_SMALL() {
    return this.ERROR;
  },
  get TOO_SMALL_SAME_COUNTRY() {
    return this.ERROR;
  },
  get DISAPPROVED() {
    return { ...this.ERROR, labelKey: "DISAPPROVED_AGAINST_ADS_POLICIES" };
  },
  // default case for unhandled status
  UNKNOWN: {
    icon: ["fas", "question-circle"],
    color: "text-gray-400",
    tagType: "is-default"
  }
};
