<template>
  <div>
    <div class="flex items-center mb-4">
      <fa :icon="statusStyle.icon" size="lg" :class="statusStyle.color" />

      <h1 class="font-bold text-xl ml-3 source-sans-pro text-gray-900">
        {{ name }}
      </h1>

      <b-button
        v-if="tab"
        type="is-ghost"
        size="is-small"
        tag="router-link"
        :to="{ name: 'facebookAction', params: { ...$route.params, tab } }"
        :disabled="settingStatus !== 'CONFIGURED'"
        class="ml-auto"
        style="text-decoration: none; border: none; background-color: transparent"
      >
        <span
          class="text-xs uppercase font-bold text-activation tracking-widest"
        >
          {{ $t(status === "NOT_CONFIGURED" ? "CONFIGURE" : "EDIT") }}
        </span>
      </b-button>
    </div>

    <Loader :loading="loading">
      <ErrorMessage :error="error">
        <Overlay
          :visible="settingStatus !== 'CONFIGURED'"
          class="bg-white rounded shadow-md py-5 px-4 overflow-hidden"
          :style="settingStatus !== 'CONFIGURED' ? 'min-height: 10rem' : ''"
        >
          <template #overlay>
            <UnavailableStepMessage />
          </template>
          <slot />
        </Overlay>
      </ErrorMessage>
    </Loader>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import Overlay from "@/components/Overlay.vue";
import UnavailableStepMessage from "@/FacebookAction/components/UnavailableStepMessage.vue";
import { ACTION_STEP_STATUS_STYLE } from "@/FacebookAction/action.const.js";

export default {
  components: { Loader, ErrorMessage, Overlay, UnavailableStepMessage },
  props: {
    status: { type: String, default: "NOT_CONFIGURED" },
    name: { type: String, required: true },
    tab: String,
    loading: Boolean,
    error: String,
    settingStatus: { type: String, default: "CONFIGURED" }
  },
  computed: {
    statusStyle: ({ status }) =>
      ACTION_STEP_STATUS_STYLE[status] || ACTION_STEP_STATUS_STYLE.UNKNOWN
  }
};
</script>
