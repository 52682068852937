<template>
  <Loader :loading="$apollo.queries.estimatedCoverage.loading">
    <ErrorMessage :error="error">
      <label class="label">
        {{ $t("DAILY_ESTIMATED_COVERAGE") }}
        <b-tooltip
          type="is-dark"
          class="ml-1"
          multilined
          :label="
            `${$t(
              'THESE_ESTIMATES_ARE_PROVIDED_FOR_INFORMATIONAL_PURPOSES_ONLY'
            )}. ${$t('THIS_IS_NOT_AT_ALL_A_GUARANTEE_OF_RESULTS')}.`
          "
        >
          <fa :icon="['fas', 'question-circle']" />
        </b-tooltip>
      </label>

      <VueApexCharts
        ref="budgetChart"
        type="line"
        :options="chartOptions"
        :series="chartSeries"
      />
      <div class="text-gray-500 text-center">
        <fa :icon="['fas', 'info-circle']" class="text-gray-400 mr-3" />
        {{ $t("YOU_CAN_CLICK_ON_THE_GRAPH_TO_SELECT_AN_AMOUNT") }}
      </div>
    </ErrorMessage>
  </Loader>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import Loader from "@/components/Loader.vue";
import VueApexCharts from "vue-apexcharts";
import estimatedCoverageQuery from "./queries/estimatedCoverage.gql";

export default {
  components: { ErrorMessage, Loader, VueApexCharts },
  data: () => ({ error: "", estimatedCoverage: [] }),
  apollo: {
    estimatedCoverage: {
      query: estimatedCoverageQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { id: actionId };
      },
      update: ({ facebookAction: { deliveryEstimate } = {} }) =>
        deliveryEstimate?.estimates.map(({ spend, ...rest }) => ({
          ...rest,
          spend: Math.round(spend / 100)
        })) ?? [],
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: {
    chartOptions: ({ estimatedCoverage = [], $i18n, updateAmount, $refs }) => ({
      xaxis: {
        type: "numeric",
        categories: estimatedCoverage.map(({ spend }) => spend),
        labels: {
          formatter: value =>
            value.toLocaleString(undefined, {
              style: "currency",
              currency: "EUR"
            })
        },
        title: { text: $i18n.t("AMOUNT") },
        tickAmount: 10
      },
      yaxis: {
        labels: { formatter: (value = 0) => value.toFixed(0) },
        title: { text: $i18n.t("PEOPLES") }
      },
      stroke: { curve: "smooth" },
      chart: {
        animations: {
          speed: 250,
          animateGradually: { enabled: false },
          dynamicAnimation: { speed: 250 }
        },
        events: {
          markerClick: (_a, _b, { dataPointIndex }) =>
            updateAmount(estimatedCoverage[dataPointIndex].spend),
          mounted: () => $refs.budgetChart.zoomX(0, 5000)
        }
      }
    }),
    chartSeries: ({ estimatedCoverage = [], $i18n }) => [
      {
        name: $i18n.t("ESTIMATED_CLICKS"),
        data: estimatedCoverage.map(({ actions }) => +actions)
      },
      {
        name: $i18n.t("ESTIMATED_IMPRESSIONS"),
        data: estimatedCoverage.map(({ impressions }) => +impressions)
      },
      {
        name: $i18n.t("ESTIMATED_COVERAGE"),
        data: estimatedCoverage.map(({ reach }) => +reach)
      }
    ]
  },
  methods: {
    updateAmount(amount) {
      +amount && this.$emit("updateAmount", amount);
    }
  }
};
</script>

<style scoped></style>
