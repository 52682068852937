import GeneralTab from "./GeneralTab/GeneralTab.vue";
import SettingTab from "./SettingTab/SettingTab.vue";
import AudienceTab from "./AudienceTab/AudienceTab.vue";
import BudgetTab from "./BudgetTab/BudgetTab.vue";
import VisualTab from "./VisualTab/VisualTab.vue";
import ResultTab from "./ResultTab/ResultTab.vue";

const tabs = [
  { labelKey: "SUMMARY", path: "summary", component: GeneralTab },
  { labelKey: "INITIALIZATION", path: "initialization", component: SettingTab },
  { labelKey: "AUDIENCE", path: "audience", component: AudienceTab },
  { labelKey: "BUDGET", path: "budget", component: BudgetTab },
  { labelKey: "VISUAL", path: "visual", component: VisualTab },
  { labelKey: "RESULTS", path: "results", component: ResultTab }
];

export default tabs;
