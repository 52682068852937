<template>
  <span>
    <b-button
      type="is-primary"
      @click="isConfirmModalOpen = true"
      :loading="loading"
      v-bind="$attrs"
    >
      {{ $t("LAUNCH_ACTION") }}
    </b-button>
    <ConfirmModal
      :is-open="isConfirmModalOpen"
      :loading="loading"
      @close="isConfirmModalOpen = false"
      @validate="startAction"
      class="has-text-left"
    >
      <div class="has-text-dark">
        {{
          $t(
            "THIS_ACTION_WILL_BE_BROADCAST_FROM_STARTDATE_TO_ENDDATE_FOR_A_MAXIMUM_AMOUNT_OF_AMOUNT",
            {
              ...budgetDates,
              amount: (lifetimeBudget / 100).toLocaleString()
            }
          )
        }}.
        <br />
        <br />
        {{ $t("ARE_YOU_SURE") }}
      </div>
    </ConfirmModal>
  </span>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";
import startActionQuery from "./queries/startAction.gql";
import updateBudgetQuery from "@/FacebookAction/BudgetTab/queries/updateBudget.gql";
import { success, error } from "@/helpers/notification.js";

const budgetDateToLocaleString = date =>
  new Date(date).toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  });

export default {
  components: { ConfirmModal },
  props: {
    startTime: { type: [String, Date], default: "" },
    endTime: { type: [String, Date], default: "" },
    lifetimeBudget: { type: String, default: "0" }
  },
  data: () => ({ isConfirmModalOpen: false, loading: false, error: "" }),
  computed: {
    budgetDates: ({ startTime, endTime }) => ({
      startDate: budgetDateToLocaleString(startTime),
      endDate: budgetDateToLocaleString(endTime)
    })
  },
  methods: {
    async startAction() {
      this.loading = true;
      const { startTime, endTime, lifetimeBudget } = this;
      const { actionId } = this.$route.params;

      if (new Date(startTime) < Date.now())
        return error(this.$t("START_DATE_IS_IN_THE_PAST"));
      if (new Date(endTime) < Date.now())
        return error(this.$t("END_DATE_IS_IN_THE_PAST"));

      await this.$apollo
        .mutate({
          mutation: updateBudgetQuery,
          variables: {
            actionId,
            budget: {
              startDate: new Date(startTime).toISOString(),
              endDate: new Date(endTime).toISOString(),
              lifetimeBudget
            }
          }
        })
        .catch(({ graphQLErrors, networkError }) => {
          const errorMsg = JSON.stringify(
            networkError || graphQLErrors[0].message
          );
          error(errorMsg);
          throw new Error(errorMsg); // and so next lines will not be executed
        });

      this.$apollo
        .mutate({
          mutation: startActionQuery,
          variables: { actionId }
        })
        .then(() => success(this.$t("SUCCESSFULLY_SAVED")))
        .catch(({ graphQLErrors, networkError }) =>
          error(JSON.stringify(networkError || graphQLErrors[0].message))
        )
        .finally(() =>
          Object.assign(this.$data, {
            loading: false,
            isConfirmModalOpen: false
          })
        );
    }
  }
};
</script>

<style scoped></style>
