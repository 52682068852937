<template>
  <ErrorMessage :error="error">
    <div class="flex">
      <ValidationProvider
        class="block w-1/2 mr-2"
        style="margin-bottom: 0"
        :name="`&quot;${$t('COUNTRY')}&quot;`"
        rules="required"
        immediate
        v-slot="{ flags: { pristine }, errors }"
      >
        <b-field
          :label="$t('COUNTRY')"
          :type="!pristine && errors[0] ? 'is-danger' : ''"
        >
          <b-taginput
            v-model="target.countries"
            :data="filteredCountries"
            :placeholder="$t('SEARCH')"
            :disabled="disabled"
            field="countryName"
            autocomplete
            open-on-focus
            attached
            @typing="input => (countriesSearch = input)"
            @remove="removeCountry"
          >
            <template #default="{ option: { countryName } }">
              {{ countryName }}
            </template>
          </b-taginput>
        </b-field>
        <div v-show="!pristine && errors[0]" class="has-text-danger -mt-2 mb-2">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
      <ValidationProvider
        class="block w-1/2 ml-2"
        style="margin-bottom: 0"
        immediate
      >
        <b-field :label="$t('CITY')" :type="citiesError ? 'is-danger' : ''">
          <b-taginput
            v-model="target.cities"
            :data="filteredCities"
            :placeholder="$t('SEARCH')"
            :loading="$apollo.queries.cities.loading"
            :disabled="disabled || !target.countries.length"
            field="name"
            autocomplete
            open-on-focus
            attached
            @typing="typingCity"
            @add="addCity"
          >
            <template #default="{ option: { name } }">
              {{ name }}
            </template>
          </b-taginput>
        </b-field>
        <div v-show="citiesError" class="has-text-danger -mt-2">
          {{ citiesError }}
        </div>
      </ValidationProvider>
    </div>

    <ValidationObserver v-slot="{ errors: { kmValidationField } }">
      <div class="flex items-center">
        <ValidationProvider immediate>
          <b-checkbox
            v-model="target.withRadius"
            :disabled="!target.cities.length"
          >
            {{ $t("EXPAND") }}
          </b-checkbox>
        </ValidationProvider>
        <ValidationProvider
          vid="kmValidationField"
          rules="min_value:18"
          :name="`&quot;Km&quot;`"
          v-slot="{ flags: { pristine }, errors }"
          immediate
        >
          <b-field
            class="mx-3"
            :type="!pristine && errors[0] ? 'is-danger' : ''"
          >
            <b-input
              type="number"
              v-model="target.citiesRadius"
              :disabled="!target.withRadius"
              class="w-40"
            />
            <p class="control">
              <span
                class="button is-static"
                :class="{ 'border-none': !target.withRadius }"
              >
                Km
              </span>
            </p>
          </b-field>
        </ValidationProvider>
        {{ $t("AROUND_CITIES") }}
      </div>
      <div
        v-show="kmValidationField && kmValidationField[0]"
        class="has-text-danger mt-1"
      >
        {{ $t("FACEBOOK_LIMITS_THE_MINIMUM_RADIUS_TO_18KM") }}
      </div>
    </ValidationObserver>
  </ErrorMessage>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage.vue";
import { escapeRegExp } from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import COUNTRIES from "./countries.const.js";
import citiesQuery from "./queries/cities.gql";

export default {
  components: { ValidationObserver, ValidationProvider, ErrorMessage },
  props: { disabled: { type: Boolean, default: false } },
  data: () => ({
    countriesSearch: "",
    citiesSearch: "",
    citiesError: "",
    error: ""
  }),
  inject: ["audienceTab"],
  apollo: {
    cities: {
      query: citiesQuery,
      variables() {
        return { city: this.citiesSearch };
      },
      debounce: 500,
      update({ listCities }) {
        return this.citiesSearch && listCities ? listCities : [];
      },
      skip() {
        return !this.citiesSearch;
      },
      error({ graphQLErrors, networkError }) {
        if (graphQLErrors) return (this.citiesError = graphQLErrors[0].message);
        this.error = JSON.stringify(networkError);
      }
    }
  },
  computed: {
    target: ({ audienceTab: { target } }) => target,
    filteredCountries: ({ countriesSearch, target: { countries } }) =>
      COUNTRIES.filter(
        ({ countryName }) =>
          RegExp(escapeRegExp(countriesSearch), "i").test(countryName) &&
          !countries.some(country => country.countryName === countryName)
      ),
    filteredCities: ({ cities = [], target: { countries } }) =>
      cities.filter(({ countryCode }) =>
        countries.some(country => country.countryCode === countryCode)
      ),
    targetedCities: {
      get: ({ value }) => (value ? value.cities : []),
      set(cities) {
        this.$emit("input", { ...this.value, cities });
      }
    }
  },
  methods: {
    typingCity(citiesSearch) {
      Object.assign(this, {
        citiesSearch,
        citiesError: "",
        ...(citiesSearch || { cities: [] })
      });
    },
    addCity() {
      Object.assign(this, { citiesSearch: "", cities: [] });
    },
    removeCountry({ countryCode }) {
      this.target.cities = this.target.cities.filter(
        city => city.countryCode !== countryCode
      );
    }
  }
};
</script>

<style scoped></style>
