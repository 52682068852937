<template>
  <div class="flex">
    <div class="w-18">
      <img :src="require('./facebookAudienceNetworkLogo.png')" class="w-12" />
    </div>
    <div class="ml-5 pl-5 border-l">
      <div class="poppins pb-3">{{ $t("AUDIENCE_NETWORK_BY_FACEBOOK") }}</div>
      <div
        class="field"
        v-for="(fbSwitch, key) in audienceNetworkSwitches"
        :key="key"
      >
        <b-switch v-if="!readonly" v-model="vm[key]" :disabled="disabled">
          {{ $t(fbSwitch) }}
        </b-switch>
        <div v-if="readonly" class="flex">
          <fa
            :icon="vm[key] ? ['fas', 'check'] : ['fas', 'times']"
            class="mr-2"
            :class="vm[key] ? 'text-green-500' : 'text-gray-400'"
            fixed-width
          />
          <div>{{ $t(fbSwitch) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const audienceNetworkSwitches = {
  audienceNetwork: "AUDIENCE_NETWORK"
};

export default {
  props: {
    value: {
      type: Object,
      default: () =>
        // default value for each switch is "false"
        Object.keys(audienceNetworkSwitches).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {}
        )
    },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    vm: vm => vm, // allow access to "this" (as "vm") in template
    // generate a computed getter/setter for each switch for v-model support
    audienceNetworkSwitches: () => audienceNetworkSwitches,
    ...Object.keys(audienceNetworkSwitches).reduce(
      (acc, switchKey) => ({
        ...acc,
        [switchKey]: {
          get: ({ value }) => value[switchKey],
          set(value) {
            this.$emit("input", { ...this.value, [switchKey]: value });
          }
        }
      }),
      {}
    )
  }
};
</script>

<style scoped></style>
