var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Loader',{staticClass:"card shadow-md",attrs:{"loading":_vm.$apollo.loading}},[_c('ErrorMessage',{attrs:{"error":_vm.error}},[_c('Overlay',{staticClass:"p-5",attrs:{"visible":_vm.waitingFacebookApi || !_vm.settingConfigured},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.waitingFacebookApi),expression:"waitingFacebookApi"}]},[_c('div',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("FINDING_YOUR_FUTURE_AUDIENCE"))+" ")]),_c('b-progress',{attrs:{"type":"is-primary","size":"is-small"}}),_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("THIS_MAY_TAKE_UP_TO_A_FEW_HOURS"))+" ")])],1),_c('UnavailableStepMessage',{directives:[{name:"show",rawName:"v-show",value:(!_vm.settingConfigured),expression:"!settingConfigured"}]})]},proxy:true}])},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var pristine = ref.pristine;
var reset = ref.reset;
return [_c('div',{staticClass:"flex mb-5"},[_c('div',{staticClass:"flex-grow"},[_c('StepStatusTag',{attrs:{"status":_vm.audienceStatus}})],1),_c('b-button',{attrs:{"type":"is-primary","loading":_vm.loading,"disabled":pristine || invalid || _vm.audienceLength < 100},on:{"click":function($event){return _vm.save(reset)}}},[_vm._v(" "+_vm._s(_vm.$t("SAVE"))+" ")])],1),_c('div',{staticClass:"flex items-end mb-4"},[_c('ValidationProvider',{staticClass:"block w-1/2 mr-4",staticStyle:{"margin-bottom":"0"},attrs:{"name":("\"" + (_vm.$t('NAME')) + "\""),"rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.flags.pristine;
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('NAME'),"type":!pristine && errors[0] ? 'is-danger' : ''}},[_c('b-input',{attrs:{"disabled":_vm.action.status === 'DONE'},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!pristine && errors[0]),expression:"!pristine && errors[0]"}],staticClass:"has-text-danger -mt-2"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"w-1/2 ml-4 mb-3"},[_c('b-message',{directives:[{name:"show",rawName:"v-show",value:(!_vm.action.audience),expression:"!action.audience"}],staticClass:"field-message",staticStyle:{"margin-bottom":"0"},attrs:{"type":"is-info"}},[_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("CREATE_THIS_AUDIENCE_TO_BE_ABLE_TO_USE_IT"))+" ")])]),_c('b-message',{directives:[{name:"show",rawName:"v-show",value:(_vm.action.audience && _vm.action.audience.status === 'READY'),expression:"action.audience && action.audience.status === 'READY'"}],staticClass:"field-message",staticStyle:{"margin-bottom":"0"},attrs:{"type":"is-success"}},[_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("THIS_AUDIENCE_IS_READY_TO_BE_USED"))+" ")])]),_c('b-message',{directives:[{name:"show",rawName:"v-show",value:(_vm.action.audience && _vm.action.audience.status === 'ERROR'),expression:"action.audience && action.audience.status === 'ERROR'"}],staticClass:"field-message",staticStyle:{"margin-bottom":"0"},attrs:{"type":"is-danger"}},[_c('div',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(
                  _vm.$t(
                    'THERE_IS_AN_ISSUE_WITH_THIS_AUDIENCE_PLEASE_CONTACT_OUR_SUPPORT'
                  )
                )}})]),_c('b-message',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.action.audience && _vm.action.audience.status === 'TOO_SMALL'
              ),expression:"\n                action.audience && action.audience.status === 'TOO_SMALL'\n              "}],staticClass:"field-message",staticStyle:{"margin-bottom":"0"},attrs:{"type":"is-danger"}},[_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("SOURCE_AUDIENCE_DOESNT_CONTAINS_ENOUGH_CONTACTS"))+" ")])]),_c('b-message',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.action.audience &&
                  _vm.action.audience.status === 'TOO_SMALL_SAME_COUNTRY'
              ),expression:"\n                action.audience &&\n                  action.audience.status === 'TOO_SMALL_SAME_COUNTRY'\n              "}],staticClass:"field-message",staticStyle:{"margin-bottom":"0"},attrs:{"type":"is-danger"}},[_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t( "SOURCE_AUDIENCE_DOESNT_CONTAINS_ENOUGH_CONTACTS_IN_THE_SELECTED_COUNTRY" ))+" ")])])],1)],1),_c('div',{staticClass:"flex"},[_c('SourceForm',{staticClass:"w-1/2 py-8 pr-8 flex-shrink-0",attrs:{"disabled":_vm.action.status === 'DONE'}}),_c('TargetForm',{staticClass:"w-1/2 py-8 pl-8 border-l-2 border-gray-200 flex-shrink-0",attrs:{"disabled":_vm.action.status === 'DONE'}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }