var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Loader',{attrs:{"loading":_vm.$apollo.loading}},[_c('ErrorMessage',{attrs:{"error":_vm.error}},[_c('b-tabs',{staticClass:"facebook-action-tabs mb-0",attrs:{"value":_vm.tabIndex}},_vm._l((_vm.tabs),function(ref,index){
var labelKey = ref.labelKey;
var path = ref.path;
var component = ref.component;
return _c('b-tab-item',{key:path,attrs:{"label":_vm.$t(labelKey)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"py-2 px-4 text-sm font-semibold hover:text-activation transition-colors duration-500",class:index === _vm.tabIndex
                ? 'border-b-2 border-activation'
                : 'text-gray-600',on:{"click":function($event){$event.stopPropagation();return _vm.changeTab(index)}}},[_vm._v(" "+_vm._s(_vm.$t(labelKey))+" ")])]},proxy:true}],null,true)},[_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(formValidation){return [(index === _vm.tabIndex)?_c(component,{ref:"currentTab",refInFor:true,tag:"component",attrs:{"form-validation":formValidation}}):_vm._e()]}}],null,true)})],1)}),1),_c('Modal',{attrs:{"is-open":_vm.openSaveModal,"title":_vm.$t('UNSAVED_CHANGED')},on:{"close":function($event){_vm.loading || (_vm.openSaveModal = false)}}},[_vm._v(" "+_vm._s(_vm.$t("SOME_CHANGES_HAVENT_BEEN_SAVED_YET"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("WHAT_DO_YOU_WANT_TO_DO"))+" "),_c('template',{slot:"foot"},[_c('b-button',{attrs:{"disabled":_vm.loading},on:{"click":_vm.continueEditing}},[_vm._v(" "+_vm._s(_vm.$t("CONTINUE_EDITING"))+" ")]),_c('b-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.goToTab(_vm.nextTabIndex)}}},[_vm._v(" "+_vm._s(_vm.$t("LEAVE_WITHOUT_SAVING"))+" ")]),_c('b-button',{attrs:{"loading":_vm.loading,"disabled":_vm.$refs.currentTab &&
              _vm.$refs.currentTab[0] &&
              !_vm.$refs.currentTab[0].isValid},on:{"click":_vm.saveAndLeave}},[_vm._v(" "+_vm._s(_vm.$t("SAVE_AND_LEAVE"))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }