<template>
  <div>
    <article v-if="error" class="message is-danger" style="margin-top: 20px">
      <button
        v-if="closable"
        class="delete is-pulled-right"
        style="margin: 10px 10px 0 0"
        aria-label="delete"
        @click="$emit('close')"
      />
      <div class="message-body">
        <h5>{{ $t("OUPS_SOMETHING_GOES_WRONG") }}</h5>
        <p style="margin-top: 5px">
          {{ $t("TRY_AGAIN_IN_A_FEW_MINUTES_OR_CONTACT_OUR_SUPPORT") }}
        </p>
        <div style="margin-bottom: 5px;">
          <span
            style="cursor: pointer"
            class="is-size-7"
            @click="showDetails = !showDetails"
          >
            <span
              style="text-decoration: underline dotted; margin-right: 3px"
              >{{ $t("SEE_TECHNICAL_DETAILS") }}</span
            >
            <fa
              :icon="
                showDetails ? ['fal', 'angle-down'] : ['fal', 'angle-right']
              "
            />
          </span>
          <pre
            v-show="showDetails"
            v-html="error"
            class="has-background-light is-size-7 break-all mt-2 rounded"
            style="white-space: pre-wrap"
          ></pre>
        </div>
        <a
          style="margin-top: 10px"
          class="button is-info"
          href="mailto:support@delight-data.com"
          target="_top"
        >
          <span class="icon">
            <fa :icon="['fas', 'envelope']" />
          </span>
          <span>support@delight-data.com</span>
        </a>
      </div>
    </article>
    <slot v-else />
  </div>
</template>

<script>
export default {
  props: {
    error: { type: String },
    closable: { type: Boolean, default: false }
  },
  data() {
    return { showDetails: false };
  }
};
</script>

<style scoped></style>
