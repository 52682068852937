<template>
  <SummaryContainer
    :name="$t('VISUAL')"
    :status="visualStatus"
    tab="visual"
    :loading="$apollo.loading"
    :error="error"
    :setting-status="settingStatus"
  >
    <div
      v-show="visualStatus === 'NOT_CONFIGURED'"
      class="text-center text-gray-500"
    >
      {{ $t("THIS_STEP_ISNT_CONFIGURED_YET") }}
    </div>
    <div v-show="visualStatus !== 'NOT_CONFIGURED'" class="flex">
      <div class="w-1/2 pr-8">
        <div class="flex py-2">
          <div class="w-1/3 label">{{ $t("NAME") }}</div>
          <div>{{ action.visual && action.visual.name }}</div>
        </div>
        <div class="flex py-2">
          <div class="w-1/3 label">{{ $t("CLICK_LINK") }}</div>
          <a
            :href="action.visual && action.visual.link"
            target="_blank"
            class="text-blue-600 underline"
          >
            {{ action.visual && action.visual.link }}
          </a>
        </div>
      </div>

      <TemplatePreview
        class="w-1/2 pl-8 border-l"
        style="min-width: 530px"
        :template="action.visual"
        preview-only
      />
    </div>
  </SummaryContainer>
</template>

<script>
import SummaryContainer from "../SummaryContainer.vue";
import TemplatePreview from "@/FacebookAction/VisualTab/components/TemplatePreview/TemplatePreview.vue";
import actionQuery from "./queries/action.gql";
import { settingStatus } from "@/FacebookAction/SettingTab/SettingTab.vue";
import { visualStatus } from "@/FacebookAction/VisualTab/VisualTab.vue";

export default {
  components: { SummaryContainer, TemplatePreview },
  data: () => ({ action: {}, error: "" }),
  apollo: {
    action: {
      query: actionQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { actionId };
      },
      update: ({ facebookAction }) => facebookAction,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: { visualStatus, settingStatus }
};
</script>
