import { apolloClient } from "@/vue-apollo.js";
import gql from "graphql-tag";
import axios from "axios";

const { origin } = window.location;
const {
  VUE_APP_FACEBOOK_APP_ID: appId,
  // in dev env and due to Facebook HTTPS requirements,
  // we have to use a manually set access token in .env.development.local
  // see README.md for details
  VUE_APP_FACEBOOK_ACCESS_TOKEN: accessToken = ""
} = process.env;

const config = {
  baseURL: "https://graph.facebook.com/v20.0",
  params: { access_token: accessToken }
};

let fetchingAccessToken = false;
let pendingResolvers = [];

const resolveAll = (resolver, payload) => {
  pendingResolvers.forEach(resolvers => resolvers[resolver](payload));
  pendingResolvers = [];
};

const fetchAccessToken = () => {
  fetchingAccessToken = true;
  return apolloClient
    .query({
      query: gql`
        {
          generateClientCodeToken
        }
      `
    })
    .then(({ data: { generateClientCodeToken } }) =>
      axios.get("/oauth/access_token", {
        ...config,
        params: {
          code: generateClientCodeToken,
          client_id: appId,
          // This parameter must be the same redirect_uri as the one used to
          // register the customer account
          redirect_uri: origin
        }
      })
    )
    .then(({ data: { access_token } }) => {
      config.params.access_token = access_token;
      return access_token;
    })
    .finally(() => (fetchingAccessToken = false));
};

export const getConfig = params =>
  new Promise((resolve, reject) => {
    pendingResolvers = [...pendingResolvers, { resolve, reject }];

    if (config.params.access_token) {
      return resolveAll("resolve", {
        ...config,
        params: { ...config.params, ...params }
      });
    }

    if (!fetchingAccessToken) {
      fetchAccessToken()
        .then(access_token =>
          resolveAll("resolve", {
            ...config,
            params: { ...config.params, access_token, ...params }
          })
        )
        .catch(err => resolveAll("reject", err));
    }
  });

export const get = ({ url, params = {} }) =>
  getConfig(params).then(config => axios.get(url, config));

export default { get };
