<template>
  <SummaryContainer
    :name="$t('AUDIENCE')"
    :status="audienceStatus"
    tab="audience"
    :loading="$apollo.loading"
    :error="error"
    :setting-status="settingStatus"
  >
    <div
      v-show="audienceStatus === 'NOT_CONFIGURED'"
      class="text-center text-gray-500"
    >
      {{ $t("THIS_STEP_ISNT_CONFIGURED_YET") }}
    </div>
    <b-message
      v-if="audienceStatus === 'ERROR'"
      type="is-danger"
      class="field-message"
    >
      <div
        v-html="
          $t('THERE_IS_AN_ISSUE_WITH_THIS_AUDIENCE_PLEASE_CONTACT_OUR_SUPPORT')
        "
      ></div>
    </b-message>
    <b-message
      v-if="audienceStatus === 'TOO_SMALL'"
      type="is-danger"
      class="field-message"
    >
      <div>
        {{ $t("SOURCE_AUDIENCE_DOESNT_CONTAINS_ENOUGH_CONTACTS") }}
      </div>
    </b-message>

    <div v-show="audienceStatus !== 'NOT_CONFIGURED'" class="flex">
      <div class="w-1/2 pr-8">
        <span class="label text-3xl pb-2">{{ $t("SOURCE") }}</span>
        <div class="flex py-2">
          <div class="w-1/3 label">{{ $t("NAME") }}</div>
          <div class="w-2/3 truncate">
            {{ source.name }}
          </div>
        </div>
        <div class="flex py-2">
          <div class="w-1/3 label">{{ $t("TYPE") }}</div>
          <div>{{ source.type }}</div>
        </div>
        <div v-if="source.bookmarks.length" class="flex py-2">
          <div class="w-1/3 label">{{ $t("BOOKMARKS") }}</div>
          <div>
            <div v-for="{ id, name } in source.bookmarks" :key="id">
              <fa :icon="['fal', 'bookmark']" class="mr-2" />
              {{ name }}
            </div>
          </div>
        </div>
        <div v-if="source.sourceFile.name" class="flex py-2">
          <div class="w-1/3 label">{{ $t("CSV_FILE") }}</div>
          <div>
            <fa :icon="['fal', 'file-csv']" class="mr-2 text-green-600" />
            {{ source.sourceFile.name }}
          </div>
        </div>
        <div class="flex py-2">
          <div class="w-1/3 label">{{ $t("SOURCE_CONTACTS") }}</div>
          <div>{{ source.countSource }}</div>
        </div>
      </div>
      <div class="w-1/2 pl-8">
        <span class="label text-3xl pb-2">{{ $t("AUDIENCE_REFINEMENT") }}</span>
        <div
          v-show="audienceStatus === 'CREATING'"
          class="flex items-center justify-center"
        >
          <div class="flex-grow p-8">
            <div class="mb-3 text-center">
              {{ $t("FINDING_YOUR_FUTURE_AUDIENCE") }}
            </div>
            <b-progress type="is-primary" size="is-small" />
            <div class="mt-4 text-center">
              {{ $t("THIS_MAY_TAKE_UP_TO_A_FEW_HOURS") }}
            </div>
          </div>
        </div>
        <div v-show="['READY', 'TOO_SMALL', 'ERROR'].includes(audienceStatus)">
          <div v-show="target.countries.length" class="flex py-2">
            <div class="w-1/3 flex-shrink-0 label">
              {{ $t("COUNTRIES") }}
            </div>
            <div>
              <b-tag
                v-for="countryName in target.countries"
                :key="countryName"
                class="mr-1 mb-1"
              >
                {{ countryName }}
              </b-tag>
            </div>
          </div>
          <div v-show="target.cities.length" class="flex py-2">
            <div class="w-1/3 flex-shrink-0 label">
              {{ $t("CITIES") }}
            </div>
            <div>
              <b-tag
                v-for="cityName in target.cities"
                :key="cityName"
                class="mr-1 mb-1"
              >
                {{ cityName }}
              </b-tag>
              <div class="mb-1" v-show="target.citiesRadius">
                <fa
                  :icon="['fas', 'check']"
                  class="text-green-500 mr-1"
                  size="sm"
                />
                {{ $t("EXPAND") }} {{ target.citiesRadius }}km
                {{ $t("AROUND_CITIES") }}
              </div>
            </div>
          </div>
          <div class="flex py-2">
            <div class="w-1/3 label">{{ $t("AGE") }}</div>
            <div>
              {{ $t("FROM_AGEMIN_TO_AGEMAX_YEARS", target.ageRange) }}
            </div>
          </div>
          <div class="flex py-2">
            <div class="w-1/3 label">{{ $t("GENDER") }}</div>
            <div>{{ $t(genders[target.gender]) }}</div>
          </div>
          <div v-show="target.interests.length" class="flex py-2">
            <div class="w-1/3 flex-shrink-0 label">
              {{ $t("INTERESTS") }}
            </div>
            <div>
              <b-tag
                v-for="interestName in target.interests"
                :key="interestName"
                class="mr-1 mb-1"
              >
                {{ interestName }}
              </b-tag>
            </div>
          </div>
          <div class="flex py-2">
            <div class="w-1/3 label">{{ $t("ESTIMATED_COVERAGE") }}</div>
            <fa v-show="loading" :icon="['fas', 'spinner']" spin />
            <div v-show="!loading">
              {{ reachEstimate.toLocaleString() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </SummaryContainer>
</template>

<script>
import SummaryContainer from "../SummaryContainer.vue";
import actionQuery from "./queries/action.gql";
import COUNTRIES from "@/FacebookAction/AudienceTab/components/TargetForm/components/GeoLocationsTarget/countries.const.js";
import { settingStatus } from "@/FacebookAction/SettingTab/SettingTab.vue";
import { audienceStatus } from "@/FacebookAction/AudienceTab/AudienceTab.vue";
import { genders } from "@/FacebookAction/AudienceTab/components/TargetForm/components/GenderTarget.vue";
import { get as fbGet } from "@/helpers/facebookApi.js";
import { error } from "@/helpers/notification.js";

export default {
  components: { SummaryContainer },
  data: () => ({ action: {}, loading: false, error: "", reachEstimate: 0 }),
  apollo: {
    action: {
      query: actionQuery,
      variables() {
        const { actionId } = this.$route.params;
        return { actionId };
      },
      update({ facebookAction }) {
        this.getReachEstimate(facebookAction);

        return facebookAction;
      },
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors[0].message);
      }
    }
  },
  computed: {
    audienceStatus,
    settingStatus,
    genders: () =>
      genders.reduce(
        (acc, { value, labelKey }) => ({ ...acc, [value]: labelKey }),
        {}
      ),
    source: ({ action: { audience } }) => ({
      name: audience?.name ?? "",
      bookmarks: audience?.bookmarks ?? [],
      sourceFile: audience?.sourceFile ?? { name: "" },
      countSource: audience?.countSource ?? 0,
      type: "Lookalike"
    }),
    target: ({ action: { configuration } }) => ({
      countries: [
        ...(configuration?.targeting?.geoLocations?.countries ?? []),
        ...new Set(
          (configuration?.targeting?.geoLocations?.cities ?? []).map(
            ({ countryCode }) => countryCode
          )
        )
      ].map(
        country =>
          COUNTRIES.find(({ countryCode }) => countryCode === country)
            .countryName
      ),
      cities: (configuration?.targeting?.geoLocations?.cities ?? []).map(
        ({ name }) => name
      ),
      citiesRadius:
        configuration?.targeting?.geoLocations?.cities[0]?.radius ?? 0,
      ageRange: {
        ageMin: configuration?.targeting?.ageMin ?? 0,
        ageMax: configuration?.targeting?.ageMax ?? 0
      },
      gender: configuration?.targeting?.gender ?? "ALL",
      interests: (configuration?.targeting?.interests ?? []).map(
        ({ name }) => name
      )
    })
  },
  methods: {
    getReachEstimate({ configuration, audience }) {
      if (!audience || !audience.lookalikeId) return;

      this.loading = true;
      const { lookalikeId } = audience;
      const { adAccountId, targeting } = configuration;
      const {
        geoLocations: { countries, cities },
        ageMin,
        ageMax,
        gender,
        interests,
        publisherPlatforms
      } = targeting;

      fbGet({
        url: `/${adAccountId}/reachestimate`,
        params: {
          targeting_spec: {
            age_min: ageMin,
            age_max: ageMax,
            interests: interests.map(({ __typename, ...rest }) => rest),
            ...(gender === "ALL" || {
              genders: [genders.findIndex(({ value }) => value === gender)]
            }),
            geo_locations: {
              countries,
              cities: cities.map(({ key, radius }) => ({
                key,
                ...(radius && { distance_unit: "kilometer", radius })
              }))
            },
            publisher_platforms: publisherPlatforms.map(publisherPlatform =>
              publisherPlatform.toLowerCase()
            ),
            custom_audiences: [lookalikeId]
          }
        }
      })
        .then(({ data: { data: { users } = {} } }) => {
          this.reachEstimate = users;
        })
        .catch(({ response: { data: { error: err } } }) =>
          error(JSON.stringify(err, undefined, 2))
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>
