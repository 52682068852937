<template>
  <div>
    <div class="flex">
      <h2 class="flex-grow title is-size-4">{{ $t("SOURCE") }}</h2>
      <b-tag type="is-info">
        <div class="text-base pb-1">
          {{ `${$t("SOURCE_CONTACTS")}: ${audienceLength.toLocaleString()}` }}
        </div>
      </b-tag>
    </div>

    <div class="space-y-6">
      <div class="text-gray-500 -mt-4">
        <fa :icon="['fas', 'info-circle']" class="text-gray-400 mr-3" />
        {{
          $t(
            "ACTIVATION_WILL_FORWARD_YOUR_CONTACTS_TO_FACEBOOK_WHICH_WILL_ANALYZE_THEM_IN_ORDER_TO_DEDUCE_OBJECTIVE_CRITERIA_AND_THUS_TARGET_SIMILAR_PEOPLE"
          )
        }}
      </div>

      <b-field :label="$t('TYPE')">
        <b-select value="lookalike" expanded disabled>
          <option value="lookalike">Lookalike</option>
        </b-select>
      </b-field>

      <div>
        <label class="label capitalize">{{ $t("CONTACTS") }}</label>
        <div class="rounded" :class="{ invalid }">
          <BookmarksSource
            class="mb-4"
            :disabled="disabled"
            :audience-length="audienceLength"
          />
          <CsvSource :disabled="disabled" />
          <!-- commented until API handle it -->
          <!-- <ActionResultSource class="mt-8" :disabled="disabled" /> -->
        </div>
        <div v-show="invalid" class="has-text-danger mt-2">
          {{ $t("A_MINIMUM_OF_100_CONTACTS_IS_REQUIRED") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookmarksSource from "./components/BookmarksSource.vue";
import CsvSource from "./components/CsvSource.vue";
// commented until API handle it
// import ActionResultSource from "./components/ActionResultSource/ActionResultSource.vue";
import { mapState } from "vuex";

export default {
  components: { BookmarksSource, CsvSource },
  props: { disabled: { type: Boolean, default: false } },
  inject: ["audienceTab"],
  computed: {
    ...mapState({
      totalAudience: ({ qlik: { totalAudience } }) => totalAudience
    }),
    source: ({ audienceTab: { source } }) => source,
    audienceLength: ({
      totalAudience,
      source: { csvFile, actionResult },
      audienceTab: { action }
    }) => {
      if (action.audience) return action.audience.countSource;

      const csvFileLength = csvFile ? csvFile.length : 0;
      const actionResultLength = actionResult ? actionResult.length : 0;
      return totalAudience + csvFileLength + actionResultLength;
    },
    invalid: ({ audienceLength, source: { pristine } }) =>
      !pristine && audienceLength < 100
  }
};
</script>

<style scoped>
.invalid {
  @apply border border-red-500;
  /* box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25); */
}
</style>
